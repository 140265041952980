import { setGS } from "../core/GlobalStates";
import { SHOW_PAYMENT_GUIDE } from "../core/GlobalStateKeys";
import { GET_USER_STATES } from "../core/AppEventHandlers";
import { isValidValue } from "../core/utils";

const checkUserStates = async (navigate, page) => {
  const res = await GET_USER_STATES();
  if (!res.is_ok) {
    navigate("/");
    return;
  }
  const userStates = res.result;
  if (!isValidValue(userStates, "session_status")) {
    console.error("invalid user states");
    navigate("/");
    return;
  }
  if (userStates["session_status"] !== "LOGIN") {
    navigate("/");
    return;
  }
  if (
    !isValidValue(userStates, "subscription_id") ||
    !isValidValue(userStates, "subscription_status") ||
    userStates["subscription_id"] <= 0 ||
    userStates["subscription_status"] !== "ACTIVE"
  ) {
    setGS(SHOW_PAYMENT_GUIDE, true);
  } else {
    setGS(SHOW_PAYMENT_GUIDE, false);
  }
  navigate(`/${page}`);
};

export default checkUserStates;
