// SIGN_IN page strings
export const SIGN_IN_EMAIL = "SIGN_IN_EMAIL";
export const SIGN_IN_PASSWORD = "SIGN_IN_PASSWORD";
export const SIGN_IN_REMEMBER_EMAIL = "SIGN_IN_REMEMBER_EMAIL";
export const SIGN_IN_TEXT = "SIGN_IN_TEXT";
export const SIGN_IN_FORGOT_PASSWORD = "SIGN_IN_FORGOT_PASSWORD";
export const SIGN_IN_SUGGEST_SIGNUP = "SIGN_IN_SUGGEST_SIGNUP";

export const SIGN_IN_STRINGS = {
    SIGN_IN_EMAIL: {
        KOREAN: "이메일",
        ENGLISH: "Email"
    },
    SIGN_IN_PASSWORD: {
        KOREAN: "비밀번호",
        ENGLISH: "Password"
    },
    SIGN_IN_REMEMBER_EMAIL: {
        KOREAN: "이메일 기억하기 (7일 동안)",
        ENGLISH: "Remember Email (for 7 days)"
    },
    SIGN_IN_TEXT: {
        KOREAN: "로그인",
        ENGLISH: "Sign In"
    },
    SIGN_IN_FORGOT_PASSWORD: {
        KOREAN: "비밀번호를 잊으셨나요?",
        ENGLISH: "Forgot password?"
    },
    SIGN_IN_SUGGEST_SIGNUP: {
        KOREAN: "계정이 없으신가요? 회원가입 하세요",
        ENGLISH: "Don't have an account? Sign Up"
    }
}