import { getCookie, getRunningZone } from "./utils";

var api_path = process.env.REACT_APP_API_PATH;
var frontend_origin = "https://cloudusb.io";
if (getRunningZone() === "LOCAL") {
  api_path = "http://localhost:8000/";
  frontend_origin = "http://localhost:3000";
}

var frontend_origin = "https://cloudusb.io";

const baseConfig = (csrftoken = "", contentType) => {
  const headers = {
    "X-CSRFToken": csrftoken,
    Origin: frontend_origin,
  };
  if (contentType === "application/json") {
    headers["Content-Type"] = "application/json";
  }
  return {
    mode: "cors",
    headers: headers,
    credentials: "include",
  };
};

const make_config = (call_method, contentType) => {
  const base_config = baseConfig(getCookie("csrftoken"), contentType);
  return {
    method: call_method,
    ...base_config,
  };
};

const config_with_body = (method, data, contentType) => {
  const base_config = baseConfig(getCookie("csrftoken"), contentType);
  if (contentType === "application/json") {
    return {
      method: method,
      body: JSON.stringify(data),
      ...base_config,
    };
  }
  return {
    method: method,
    body: data,
    ...base_config,
  };
};

const make_call = (method, url, responseType = "json") => {
  return call_with_body(method, url, null, "application/json", responseType);
};

const call_with_body = async (
  method,
  url,
  data,
  contentType = "application/json",
  responseType = "json"
) => {
  try {
    var response;
    if (data == null) {
      response = await fetch(url, make_config(method, contentType));
    } else {
      response = await fetch(url, config_with_body(method, data, contentType));
    }
    if (response.ok) {
      if (responseType === "json") {
        const text = await response.text();
        if (text.length > 0) {
          return {
            is_ok: true,
            result: JSON.parse(text),
          };
        }
        return {
          is_ok: true,
        };
      }
      const result = await response.text();
      return {
        is_ok: true,
        result: result,
      };
    } else {
      const msg = await response.text();
      console.log(
        "Failed " + method + " call: ",
        url,
        " [" + response.status + "] ",
        msg
      );
      return {
        is_ok: false,
        message: msg,
      };
    }
  } catch (err) {
    console.log("err: ", err);
    return {
      is_ok: false,
      message: err,
    };
  }
};

// Cloudusb User APIs -----------------------------------------------------------------------------
export const signUp = (data) => {
  return call_with_body(
    "POST",
    `${api_path}api/v1/user/signup`,
    data,
    "application/json",
    "json"
  );
};

export const userVerify = (data) => {
  return call_with_body(
    "POST",
    `${api_path}api/v1/user/verify`,
    data,
    "application/json",
    "json"
  );
};

export const regenerateVerificationCode = (data) => {
  return call_with_body(
    "POST",
    `${api_path}api/v1/user/regenerate-verification-code`,
    data,
    "application/json",
    "json"
  );
};

export async function signIn(data) {
  return call_with_body(
    "POST",
    `${api_path}api/v1/user/login`,
    data,
    "application/json",
    "json"
  );
}

export const signOut = (data) => {
  return make_call("POST", `${api_path}api/v1/user/logout`, data);
};

export const userRetrieve = (data) => {
  return make_call(
    "GET",
    `${api_path}api/v1/user/retrieve`,
    data,
    "application/json",
    "json"
  );
};

// Cloudusb OrderableUserInfo APIs -------------------------------------------------------------------
export const getOrderableUserInfo = () => {
  return make_call("GET", `${api_path}api/v1/orderable-user-info/current-user`);
};

// Cloudusb Billing APIs -----------------------------------------------------------------------------
export const issueBillingKey = (data) => {
  return call_with_body(
    "POST",
    `${api_path}api/v1/billing/issue-billing-key`,
    data,
    "application/json",
    "json"
  );
};

export const createFirstMonthlyOrder = (data) => {
  return call_with_body(
    "POST",
    `${api_path}api/v1/order/create-first-monthly-order`,
    data,
    "application/json",
    "json"
  );
};

export const payOrder = (data) => {
  return call_with_body(
    "POST",
    `${api_path}api/v1/billing/pay-order`,
    data,
    "application/json",
    "json"
  );
};

// Cloudusb Folder APIs -----------------------------------------------------------------------------
export const createFolder = (data) => {
  return call_with_body("POST", `${api_path}api/v1/folder`, data);
};

export const getFolder = (folderId) => {
  return make_call("GET", `${api_path}api/v1/folder/${folderId}`);
};

export const updateFolder = (folderId, data) => {
  return call_with_body("PUT", `${api_path}api/v1/folder/${folderId}`, data);
};

export const deleteFolder = (folderId) => {
  return make_call("DELETE", `${api_path}api/v1/folder/${folderId}`);
};

export const getFolderTree = () => {
  return make_call("GET", `${api_path}api/v1/folder/tree`);
};

// Cloudusb File APIs -------------------------------------------------------------------------------
export const createFile = (data) => {
  return call_with_body(
    "POST",
    `${api_path}api/v1/file`,
    data,
    "multipart/form-data"
  );
};

export const getFile = (fileId) => {
  return make_call("GET", `${api_path}api/v1/file/${fileId}`);
};

export const getDownloadUri = (fileId) => {
  return make_call("GET", `${api_path}api/v1/file/download-uri/${fileId}`);
};

export const updateFile = (fileId, data) => {
  return call_with_body("PUT", `${api_path}api/v1/file/${fileId}`, data);
};

export const deleteFile = (fileId) => {
  return make_call("DELETE", `${api_path}api/v1/file/${fileId}`);
};

// Cloudusb UserState APIs -----------------------------------------------------------------------------
export const getUserState = () => {
  return make_call("GET", `${api_path}api/v1/user-state`, "json");
};

export const updateUserState = (data) => {
  return call_with_body("PUT", `${api_path}api/v1/user-state`, data);
};

export const getCurrentFolder = () => {
  return make_call("GET", `${api_path}api/v1/user-state/current-folder`);
};

// Cloudusb ServiceFeature APIs ------------------------------------------------------------------------
export const getServiceFeature = (key) => {
  return make_call("GET", `${api_path}api/v1/service-feature/show/${key}`);
};
