import React, { useRef, useEffect } from "react";
import { CURRENT_FOLDER, TRIGGER_FILES_UPLOAD } from "../core/GlobalStateKeys";
import { useGS } from "../core/GlobalStates";
import { handleFileUploads } from "./jamsil_filebrowser/handlers";
import { getPlatform, Platform } from "../core/utils";
import { flutterUploadFiles } from "../core/FlutterBridges";

export default function FileUpload({ trigger, onFilesSelected }) {
  const [triggerUpload, setTriggerUpload] = useGS(TRIGGER_FILES_UPLOAD);
  const [currentFolder] = useGS(CURRENT_FOLDER);
  const inputRef = useRef();

  useEffect(() => {
    if (!triggerUpload) {
      return;
    }
    const platform = getPlatform();
    if (platform === Platform.ANDROID || platform === Platform.IOS) {
      console.log("calling flutterUploadFiles");
      flutterUploadFiles(currentFolder.folder_id);
    } else if (inputRef.current) {
      inputRef.current.click();
    }
    setTriggerUpload(false);
  }, [triggerUpload, setTriggerUpload, currentFolder]);

  return (
    <input
      type="file"
      style={{ display: "none" }}
      ref={inputRef}
      multiple
      onChange={(e) => {
        handleFileUploads(e.target.files);
      }}
    />
  );
}
