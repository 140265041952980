export const DASHBOARD_TITLE = "DASHBOARD_TITLE";
export const DASHBOARD_SERVICE_SETTINGS = "DASHBOARD_SERVICE_SETTINGS";
export const DASHBOARD_CHARGE_LIMIT = "DASHBOARD_CHARGE_LIMIT";
export const DASHBOARD_CHARGE_LIMIT_HELPER_TEXT =
  "DASHBOARD_CHARGE_LIMIT_HELPER_TEXT";
export const DASHBOARD_DELETE_ALL_DATE = "DASHBOARD_DELETE_ALL_DATE";
export const DASHBOARD_DELETE_ALL_DATE_HELPER_TEXT =
  "DASHBOARD_DELETE_ALL_DATE_HELPER_TEXT";
export const DASHBOARD_MONTHLY_CHARGE = "DASHBOARD_MONTHLY_CHARGE";
export const DASHBOARD_SELECT_MONTH = "DASHBOARD_SELECT_MONTH";
export const DASHBOARD_DAILY_CHARGING_HISTORY =
  "DASHBOARD_DAILY_CHARGING_HISTORY";
export const DASHBOARD_TABLE_DATE = "DASHBOARD_TABLE_DATE";
export const DASHBOARD_TABLE_HOT_STORAGE_USAGE =
  "DASHBOARD_TABLE_HOT_STORAGE_USAGE";
export const DASHBOARD_TABLE_COLD_STORAGE_USAGE =
  "DASHBOARD_TABLE_COLD_STORAGE_USAGE";
export const DASHBOARD_TABLE_DAILY_CHARGE = "DASHBOARD_TABLE_DAILY_CHARGE";
export const DASHBOARD_CURRENT_CHARGE = "DASHBOARD_CURRENT_CHARGE";
export const DASHBOARD_BASE_CHARGE = "DASHBOARD_BASE_CHARGE";
export const DASHBOARD_TOTAL_DAILY_CHARGE = "DASHBOARD_TOTAL_DAILY_CHARGE";
export const DASHBOARD_TOTAL_CHARGE = "DASHBOARD_TOTAL_CHARGE";

export const DASHBOARD_STRINGS = {
  DASHBOARD_TITLE: {
    KOREAN: "대시보드",
    ENGLISH: "Dashboard",
  },
  DASHBOARD_SERVICE_SETTINGS: {
    KOREAN: "서비스 설정",
    ENGLISH: "Service Settings",
  },
  DASHBOARD_CHARGE_LIMIT: {
    KOREAN: "요금 한도 (₩)",
    ENGLISH: "Charge Limit (₩)",
  },
  DASHBOARD_CHARGE_LIMIT_HELPER_TEXT: {
    KOREAN:
      "만약 요금 한도를 설정하면 한도에 다다르면 모든 파일이 삭제되고 더이상 과금되지 않습니다.",
    ENGLISH:
      "If you set the charge limit, when the limit is reached, all files will be deleted and no more charges will be incurred.",
  },
  DASHBOARD_DELETE_ALL_DATE: {
    KOREAN: "모든 데이터 삭제 날짜",
    ENGLISH: "Delete All Data",
  },
  DASHBOARD_DELETE_ALL_DATE_HELPER_TEXT: {
    KOREAN:
      "만약 모든 데이터를 삭제할 날짜를 설정하면 이 날짜 이후로는 과금되지 않습니다.",
    ENGLISH:
      "If you set a date to delete all data, you will not be charged from this date onwards.",
  },
  DASHBOARD_MONTHLY_CHARGE: {
    KOREAN: "월별 요금",
    ENGLISH: "Monthly Charge",
  },
  DASHBOARD_SELECT_MONTH: {
    KOREAN: "월 선택",
    ENGLISH: "Select Month",
  },
  DASHBOARD_DAILY_CHARGING_HISTORY: {
    KOREAN: "일별 요금 내역",
    ENGLISH: "Daily Charging History",
  },
  DASHBOARD_TABLE_DATE: {
    KOREAN: "날짜",
    ENGLISH: "Date",
  },
  DASHBOARD_TABLE_HOT_STORAGE_USAGE: {
    KOREAN: "핫 스토리지 GB",
    ENGLISH: "Hot Storage GB",
  },
  DASHBOARD_TABLE_COLD_STORAGE_USAGE: {
    KOREAN: "콜드 스토리지 GB",
    ENGLISH: "Cold Storage GB",
  },
  DASHBOARD_TABLE_DAILY_CHARGE: {
    KOREAN: "요금 (₩)",
    ENGLISH: "Charge (₩)",
  },
  DASHBOARD_CURRENT_CHARGE: {
    KOREAN: "현재 요금",
    ENGLISH: "Current Charge",
  },
  DASHBOARD_BASE_CHARGE: {
    KOREAN: "기본 요금",
    ENGLISH: "Base Charge",
  },
  DASHBOARD_TOTAL_DAILY_CHARGE: {
    KOREAN: "일별 요금 합계",
    ENGLISH: "Total Daily Charge",
  },
  DASHBOARD_TOTAL_CHARGE: {
    KOREAN: "총 요금",
    ENGLISH: "Total Charge",
  },
};
