import { KOREAN, ENGLISH } from "../StringTable";

export const SERVICE_ATTRIBUTE_1_TITLE = "SERVICE_ATTRIBUTE_1_TITLE";
export const SERVICE_ATTRIBUTE_1_HEADER_TEXT =
  "SERVICE_ATTRIBUTE_1_HEADER_TEXT";
export const SERVICE_ATTRIBUTE_1_EXPLANATION =
  "SERVICE_ATTRIBUTE_1_EXPLANATION";
export const SERVICE_ATTRIBUTE_2_TITLE = "SERVICE_ATTRIBUTE_2_TITLE";
export const SERVICE_ATTRIBUTE_2_HEADER_TEXT =
  "SERVICE_ATTRIBUTE_2_HEADER_TEXT";
export const SERVICE_ATTRIBUTE_2_EXPLANATION =
  "SERVICE_ATTRIBUTE_2_EXPLANATION";
export const SERVICE_ATTRIBUTE_3_TITLE = "SERVICE_ATTRIBUTE_3_TITLE";
export const SERVICE_ATTRIBUTE_3_HEADER_TEXT =
  "SERVICE_ATTRIBUTE_3_HEADER_TEXT";
export const SERVICE_ATTRIBUTE_3_EXPLANATION =
  "SERVICE_ATTRIBUTE_3_EXPLANATION";
export const SERVICE_ATTRIBUTE_4_TITLE = "SERVICE_ATTRIBUTE_4_TITLE";
export const SERVICE_ATTRIBUTE_4_HEADER_TEXT =
  "SERVICE_ATTRIBUTE_4_HEADER_TEXT";
export const SERVICE_ATTRIBUTE_4_EXPLANATION =
  "SERVICE_ATTRIBUTE_4_EXPLANATION";
export const SERVICE_LEARN_MORE_TITLE = "SERVICE_LEARN_MORE_TITLE";
export const SERVICE_LEARN_MORE_HEADER_TEXT = "SERVICE_LEARN_MORE_HEADER_TEXT";
export const SERVICE_LEARN_MORE_LINK_TEXT = "SERVICE_LEARN_MORE_LINK_TEXT";

export const SERVICE_INTRO_STRINGS = {
  SERVICE_ATTRIBUTE_1_TITLE: {
    KOREAN: "적게 쓰면 적게 내는 저렴한 클라우드 스토리지 서비스",
    ENGLISH: "Cloud storage service that pays less when used less",
  },
  SERVICE_ATTRIBUTE_1_HEADER_TEXT: {
    KOREAN:
      "100G, 200G가 전부 꼭 필요하세요? 쓰신 만큼만 비용을 낼 수 있다면?!",
    ENGLISH:
      "Do you really need 100G, 200G? You can pay only for what you use!",
  },
  SERVICE_ATTRIBUTE_1_EXPLANATION: {
    KOREAN:
      "cloudusb.io는 300원의 기본요금으로 시작하여 저장한 용량과 기간에 따라 일할로 과금됩니다. 그래서 한 달에 300, 500원 정도 금액으로 클라우드 스토리지를 쓰실 수 있게 됩니다!",
    ENGLISH:
      "cloudusb.io starts with a basic fee of 300 won and is charged according to the capacity and period stored. So you can use cloud storage for about 300, 500 won a month!",
  },
  SERVICE_ATTRIBUTE_2_TITLE: {
    KOREAN: "가끔 잠깐씩 쓰는 경우라면? 300원으로 100GB 까지!",
    ENGLISH:
      "If you use it occasionally for a short time? Up to 100GB for 300 won!",
  },
  SERVICE_ATTRIBUTE_2_HEADER_TEXT: {
    KOREAN: "기본요금 300원으로 2일 동안 100G까지 사용 가능합니다.",
    ENGLISH: "You can use up to 100G for 2 days with a basic fee of 300 won.",
  },
  SERVICE_ATTRIBUTE_2_EXPLANATION: {
    KOREAN:
      "cloudusb.io는 매달 최소 300원의 기본요금이 부과됩니다. 만약 가끔씩만 클라우드 스토리지가 필요하시다면 300원을 내시고 2일 후 모든 파일을 삭제하면 추가 과금이 없습니다. 꼭 필요한 경우에 300원으로 100GB까지 이용하세요.",
    ENGLISH:
      "cloudusb.io charges 300 korean won as a base charge every month. If you only need cloud storage occasionally, pay 300 won and delete all files after 2 days to avoid additional charges. Use up to 100GB for 300 won when necessary.",
  },
  SERVICE_ATTRIBUTE_3_TITLE: {
    KOREAN: "편리하고 직관적인 UI",
    ENGLISH: "Convenient and intuitive UI",
  },
  SERVICE_ATTRIBUTE_3_HEADER_TEXT: {
    KOREAN: "일반적인 폴더와 같은 익숙한 사용 방식",
    ENGLISH: "Familiar usage like a general folder",
  },
  SERVICE_ATTRIBUTE_3_EXPLANATION: {
    KOREAN:
      "자유롭게 폴더를 만들고 구성하고 업로드, 다운로드 할 수 있습니다. 폴더 탐색기를 통해 편리하게 이동할 수 있습니다.",
    ENGLISH:
      "You can freely create, configure, upload, and download folders. You can easily move through the folder explorer.",
  },
  SERVICE_ATTRIBUTE_4_TITLE: {
    KOREAN: "언제나 쉽게 확인 가능한 사용 요금",
    ENGLISH: "Usage fee that can always be easily checked",
  },
  SERVICE_ATTRIBUTE_4_HEADER_TEXT: {
    KOREAN:
      "대시보드에서 언제나 쉽게 현재 및 과거의 월별 사용 요금을 볼 수 있습니다.",
    ENGLISH:
      "You can always easily see the current and past monthly usage fees on the dashboard.",
  },
  SERVICE_ATTRIBUTE_4_EXPLANATION: {
    KOREAN:
      "월별 사용 요금은 기본요금과 사용량에 비례하는 일 단위 요금의 합계로 계산합니다.",
    ENGLISH:
      "A monthly usage fee is calculated as the sum of a base fee and a prorated daily fee.",
  },
  SERVICE_LEARN_MORE_TITLE: {
    KOREAN: "더 알아보기",
    ENGLISH: "Learn more",
  },
  SERVICE_LEARN_MORE_HEADER_TEXT: {
    KOREAN: "상세 페이지에서 더 자세한 cloudusb.io의 기능을 확인하세요.",
    ENGLISH: "Check out more features of cloudusb.io on the details page",
  },
  SERVICE_LEARN_MORE_LINK_TEXT: {
    KOREAN: "더 알아보기",
    ENGLISH: "Learn more",
  },
};
