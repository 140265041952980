// SIGN_UP page strings
export const SIGN_UP_TITLE = "SIGN_UP_TITLE";
export const SIGN_UP_GUIDE = "SIGN_UP_GUIDE";
export const SIGN_UP_FIRST_NAME = "SIGN_UP_FIRST_NAME";
export const SIGN_UP_LAST_NAME = "SIGN_UP_LAST_NAME";
export const SIGN_UP_EMAIL = "SIGN_UP_EMAIL";
export const SIGN_UP_PASSWORD = "SIGN_UP_PASSWORD";
export const SIGN_UP_PASSWORD_CONFIRM = "SIGN_UP_PASSWORD_CONFIRM";
export const SIGN_UP_MOBILE_NUMBER = "SIGN_UP_MOBILE_NUMBER";
export const SIGN_UP_INVITATION_CODE = "SIGN_UP_INVITATION_CODE";
export const SIGN_UP_RECEIVE_SERVICE_UPDATES =
  "SIGN_UP_RECEIVE_SERVICE_UPDATES";
export const SIGN_UP_MOVE_TO_SIGN_IN_PAGE = "SIGN_UP_MOVE_TO_SIGN_IN_PAGE";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";
export const SIGN_UP_FAIL_MESSAGE = "SIGN_UP_FAIL_MESSAGE";
export const SIGN_UP_READY = "SIGN_UP_READY";
export const SIGN_UP_READY_MESSAGE = "SIGN_UP_READY_MESSAGE";

// SIGN_UP page strings
export const SIGN_UP_STRINGS = {
  SIGN_UP_TITLE: {
    KOREAN: "회원 가입",
    ENGLISH: "Sign Up",
  },
  SIGN_UP_GUIDE: {
    KOREAN: "테스트 기간에는 초청 받으신 분들만 회원 가입이 가능합니다.",
    ENGLISH: "Only invited people can sign up during testing period.",
  },
  SIGN_UP_FIRST_NAME: {
    KOREAN: "이름",
    ENGLISH: "First Name",
  },
  SIGN_UP_LAST_NAME: {
    KOREAN: "성",
    ENGLISH: "Last Name",
  },
  SIGN_UP_EMAIL: {
    KOREAN: "이메일",
    ENGLISH: "Email Address",
  },
  SIGN_UP_PASSWORD: {
    KOREAN: "비밀번호",
    ENGLISH: "Password",
  },
  SIGN_UP_PASSWORD_CONFIRM: {
    KOREAN: "비밀번호 확인",
    ENGLISH: "Confirm Password",
  },
  SIGN_UP_MOBILE_NUMBER: {
    KOREAN: "휴대폰 번호",
    ENGLISH: "Mobile Phone",
  },
  SIGN_UP_INVITATION_CODE: {
    KOREAN: "초청 코드",
    ENGLISH: "Invitation Code",
  },
  SIGN_UP_RECEIVE_SERVICE_UPDATES: {
    KOREAN: "이메일을 통해 마케팅 프로모션 및 업데이트를 받고 싶습니다.",
    ENGLISH: "I want to receive marketing promotions and updates via email.",
  },
  SIGN_UP_MOVE_TO_SIGN_IN_PAGE: {
    KOREAN: "이미 계정이 있으신가요? 로그인 하기",
    ENGLISH: "Already have an account? Sign In",
  },
  SIGN_UP_FAILED: {
    KOREAN: "회원 가입 실패",
    ENGLISH: "Sign Up Failed",
  },
  SIGN_UP_FAIL_MESSAGE: {
    KOREAN: "회원 가입에 실패했습니다.",
    ENGLISH: "Sign up failed.",
  },
  SIGN_UP_READY: {
    KOREAN: "회원 가입 준비 완료",
    ENGLISH: "Sign Up Ready",
  },
  SIGN_UP_READY_MESSAGE: {
    KOREAN: "회원 정보가 저장 되었습니다. 이메일 검증 페이지로 이동합니다.",
    ENGLISH:
      "Your information has been saved. Moving to email verification page.",
  },
};
