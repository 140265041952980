import { KOREAN, ENGLISH } from "../StringTable";

export const HEADER_HOME = "HEADER_HOME";
export const HEADER_DASHBOARD = "HEADER_DASHBOARD";
export const HEADER_ACCOUNT = "HEADER_ACCOUNT";
export const HEADER_LOGOUT = "HEADER_LOGOUT";
export const HEADER_ACCOUNT_TOOLTIP = "HEADER_ACCOUNT_TOOLTIP";

export const HEADER_STRINGS = {
  HEADER_HOME: {
    KOREAN: "홈",
    ENGLISH: "Home",
  },
  HEADER_DASHBOARD: {
    KOREAN: "대시보드",
    ENGLISH: "Dashboard",
  },
  HEADER_ACCOUNT: {
    KOREAN: "계정",
    ENGLISH: "Account",
  },
  HEADER_LOGOUT: {
    KOREAN: "로그아웃",
    ENGLISH: "Logout",
  },
  HEADER_ACCOUNT_TOOLTIP: {
    KOREAN: "계정 및 로그아웃",
    ENGLISH: "Account and Logout",
  },
};
