import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Item, FILE_TYPE, FOLDER_TYPE } from "./Item";
import { useGS } from "../../core/GlobalStates";
import {
  CURRENT_SELECTED_ITEMS,
  ITEM_ORDERING_OPTION,
  SEARCH_KEYWORD,
} from "../../core/GlobalStateKeys";
import { handleDoubleClick } from "./handlers";

const ITEM_IMAGE_SIZE = 100;
const ITEM_SPACING = 5;

/**
 * ItemView는 Jamsil Filebrowser에서 파일 및 폴더 목록을 보여주는 콤포넌트이다.
 * (현재는 아이콘 뷰만 제공되고 있는데 목록 뷰도 만들어야 함)
 * items는 파일 및 폴더 목록이며 cloudusb-backend의 folder 조회 후
 * 그 안에 있는 items를 배열로 변경하여 넣는다.
 *
 * 아래는 items dict의 예시이다. values만 배열로 변환하여 넣어야 한다.
 * "items": {
 *   "test": {
 *     "name": "test",
 *     "extension": null,
 *     "item_type": "FOLDER",
 *     "item_id": 70
 *   },
 *   "마상.png": {
 *     "name": "마상.png",
 *     "extension": "png",
 *     "item_type": "FILE",
 *     "item_id": 17
 *   }
 * }
 */
export default function ItemView({ items = [] }) {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [selectedItems, setSelectedItems] = useGS(CURRENT_SELECTED_ITEMS);
  const [itemOrderingOption] = useGS(ITEM_ORDERING_OPTION); // ["NAME_ASC/DESC"], in the future: "SIZE", "DATE"
  const [searchKeyword] = useGS(SEARCH_KEYWORD);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    const observer = new ResizeObserver(([entry]) =>
      setWidth(entry.contentRect.width)
    );
    observer.observe(ref.current);

    const ref_current = ref.current;
    return () => {
      if (!ref_current) {
        return;
      }

      observer.unobserve(ref_current);
    };
  }, []); // empty array ensures effect is only run on mount and unmount

  var gridSize = 12;
  if (width > 0) {
    gridSize = parseInt(12 / parseInt(width / (ITEM_IMAGE_SIZE * 1.1)));
    if (parseInt(width / (12 / gridSize)) < ITEM_IMAGE_SIZE * 1.1) {
      gridSize += 1;
    }
  }
  if (gridSize < 1) gridSize = 1;
  if (gridSize > 12) gridSize = 12;

  const handleClickFn = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  if (
    searchKeyword !== null &&
    searchKeyword !== undefined &&
    searchKeyword.length > 0
  ) {
    items = items.filter((item) =>
      item.name.normalize().includes(searchKeyword.normalize())
    );
  }

  if (itemOrderingOption === "NAME_ASC") {
    items.sort((a, b) => {
      if (a.name < b.name) return -1;
      return 1;
    });
  } else if (itemOrderingOption === "NAME_DESC") {
    items.sort((a, b) => {
      if (a.name < b.name) return 1;
      return -1;
    });
  }

  return (
    <Grid
      container
      spacing={ITEM_SPACING}
      ref={ref}
      sx={{ margin: 1, width: "100%" }}
    >
      {items.map((item, index) => (
        <Item
          key={index}
          gridSize={gridSize}
          imageSize={ITEM_IMAGE_SIZE}
          type={toItemType(item.item_type)}
          thumbnail_data={item.thumbnail_data}
          extension={item.extension}
          name={item.name}
          selected={selectedItems.includes(item)}
          onClick={() => handleClickFn(item)}
          onDoubleClick={() => handleDoubleClick(item)}
        />
      ))}
    </Grid>
  );
}

const toItemType = (item_type) => {
  if (item_type === "FILE") {
    return FILE_TYPE;
  } else if (item_type === "FOLDER") {
    return FOLDER_TYPE;
  }
  console.error(`Unknown item_type: ${item_type}`);
  return null;
};
