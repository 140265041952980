export const ERROR_ALREADY_EXISTS = "ERROR_ALREADY_EXISTS";
export const VERIFICATION_CODE_EXPIRED = "VERIFICATION_CODE_EXPIRED";
export const INVALID_VERIFICATION_CODE = "INVALID_VERIFICATION_CODE";

export const ERROR_STRINGS = {
  ERROR_ALREADY_EXISTS: {
    KOREAN: "입력하신 정보가 이미 존재합니다.",
    ENGLISH: "The information you entered already exists.",
  },
  VERIFICATION_CODE_EXPIRED: {
    KOREAN: "인증 코드가 만료되었습니다.",
    ENGLISH: "The verification code has expired.",
  },
  INVALID_VERIFICATION_CODE: {
    KOREAN: "유효하지 않은 인증 코드입니다.",
    ENGLISH: "Invalid verification code.",
  },
};
