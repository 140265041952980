import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function BaseTable({
  minWidth = 100,
  size = "small",
  defaultAlign = "left",
  ariaLabel = "a base table",
  data = { columns: [], rows: [] },
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: { minWidth } }} size={size} aria-label={ariaLabel}>
        <TableHead>
          <TableRow>
            {data.columns.map((column, index) => (
              <TableCell align={column.align || defaultAlign} key={index}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.rows.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {row.map((col, colIndex) => (
                <TableCell
                  align={
                    (data.columns[colIndex] && data.columns[colIndex].align) ||
                    defaultAlign
                  }
                  key={colIndex}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
