export const COMMON_OK = "COMMON_OK";
export const COMMON_CANCEL = "COMMON_CANCEL";
export const COMMON_CLOSE = "COMMON_CLOSE";
export const COMMON_YES = "COMMON_YES";
export const COMMON_NO = "COMMON_NO";
export const COMMON_MOVE = "COMMON_MOVE";
export const COMMON_SAVE = "COMMON_SAVE";

export const COMMON_STRINGS = {
  COMMON_OK: {
    KOREAN: "확인",
    ENGLISH: "OK",
  },
  COMMON_CANCEL: {
    KOREAN: "취소",
    ENGLISH: "Cancel",
  },
  COMMON_CLOSE: {
    KOREAN: "닫기",
    ENGLISH: "Close",
  },
  COMMON_YES: {
    KOREAN: "예",
    ENGLISH: "Yes",
  },
  COMMON_NO: {
    KOREAN: "아니오",
    ENGLISH: "No",
  },
  COMMON_MOVE: {
    KOREAN: "이동",
    ENGLISH: "Move",
  },
  COMMON_SAVE: {
    KOREAN: "저장",
    ENGLISH: "Save",
  },
};
