import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloudusbMainLogo from "../components/CloudusbMainLogo";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";

import { loadTossPayments } from "@tosspayments/payment-sdk";

import KoreanWonIcon from "../components/KoreanWonIcon";
import { GET_ORDERABLE_USER_INFO } from "../core/AppEventHandlers";
import { getDomainName } from "../core/utils";

const clientKey = "test_ck_OEP59LybZ8BAvg2XL6Zr6GYo7pRe";

const startTossCardRegistration = async (clientKey, customerKey) => {
  console.log("startTossCardRegistration");
  // ------ 클라이언트 키로 객체 초기화 ------
  loadTossPayments(clientKey).then((tossPayments) => {
    // ------ 카드 등록창 호출 ------
    tossPayments
      .requestBillingAuth("카드", {
        // 결제수단 파라미터 (자동결제는 카드만 지원합니다.)
        // 결제 정보 파라미터
        // 더 많은 결제 정보 파라미터는 결제창 Javascript SDK에서 확인하세요.
        // https://docs.tosspayments.com/reference/js-sdk#requestbillingauth카드-결제-정보
        customerKey: customerKey, // 고객 ID로 상점에서 만들어야 합니다. 빌링키와 매핑됩니다. 자세한 파라미터 설명은 결제 정보 파라미터 설명을 참고하세요.
        successUrl: getDomainName() + "/card-registration-success", // 카드 등록에 성공하면 이동하는 페이지(직접 만들어주세요)
        failUrl: getDomainName() + "/card-registration-fail", // 카드 등록에 실패하면 이동하는 페이지(직접 만들어주세요)
      })
      // ------ 결제창을 띄울 수 없는 에러 처리 ------
      // 메서드 실행에 실패해서 reject 된 에러를 처리하는 블록입니다.
      // 결제창에서 발생할 수 있는 에러를 확인하세요.
      // https://docs.tosspayments.com/reference/error-codes#결제창공통-sdk-에러
      .catch(function (error) {
        console.log(error);
        if (error.code === "USER_CANCEL") {
          // 결제 고객이 결제창을 닫았을 때 에러 처리
          console.log("User canceled the card registration window.");
        }
      });
  });
};

export default function Checkout() {
  const [startPayment, setStartPayment] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (startPayment) {
      GET_ORDERABLE_USER_INFO().then((res) => {
        if (res.is_ok) {
          const uinfo = res.result;
          startTossCardRegistration(clientKey, uinfo.customer_key);
        }
      });
      setStartPayment(false);
    }
  }, [startPayment, setStartPayment]);
  return (
    <div style={{ backgroundColor: "#ebf9cf", height: "100vh" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "left",
          pt: 1,
        }}
      >
        <CloudusbMainLogo
          sx={{ width: "20%", maxWidth: 130 }}
          onClick={() => navigate("/home")}
        />
      </Box>
      <Container component="main" sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              width: "100%",
              maxWidth: "sm",
              backgroundColor: "lightblue",
            }}
          >
            <CardHeader title="정기 결제 요금 정책" />
            <CardContent sx={{ backgroundColor: "white" }}>
              <Stack direction="row" sx={{ my: 1 }}>
                <CheckIcon fontSize="small" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 1 }}
                >
                  요금은 4주에 한 번 기본요금과 기본요금 이상으로 사용한 용량 및
                  조건에 따라 후불제로 과금 됩니다.
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ my: 1 }}>
                <CheckIcon fontSize="small" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 1 }}
                >
                  매일 하루 단위로 그 날의 최대 파일 저장 용량을 기준으로 그
                  날의 가격이 결정 됩니다. 만약 최대 저장 용량이 0인 날이 있다면
                  그 날은 과금하지 않습니다.
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ my: 1 }}>
                <CheckIcon fontSize="small" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 1 }}
                >
                  기본 요금은 처음 결제할 때 300원 입니다. 기본 요금으로 2일
                  동안 100GB 까지 추가 과금 없이 사용 가능합니다. 만약 기본 요금
                  적용 기간에 100GB 이상을 사용하거나 기본요금 적용 기간
                  이후에도 저장된 파일들이 있으면 후불제 요금이 계산 됩니다.
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ my: 1 }}>
                <CheckIcon fontSize="small" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 1.4 }}
                >
                  <b>결제 취소 및 환불 정책: </b> 300원은 이틀 동안 100G까시
                  사용 가능한 금액 입니다. 환불은 사용하지 않은 날에 대하여 일할
                  환불을 적용 합니다. 따라서 결제 후 다음 날이 되기전 취소하면
                  150원을 환불 합니다. 결제 하면 오늘에 대한 사용은 시작된
                  것으로 보기 때문에 오늘 사용 요금 150원은 환불하지 않습니다.
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ my: 1 }}>
                <KoreanWonIcon
                  width="15px"
                  height="15px"
                  sx={{ ml: 0.2, mt: 0.1 }}
                />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 1.4 }}
                >
                  <b>오늘 결제 금액: 300원.</b> 한 달 기본 요금. 이틀 동안
                  100G까지 사용 가능.
                </Typography>
              </Stack>
              {/*<Stack>
                <Stack direction="row" sx={{ my: 1 }}>
                  <KoreanWonIcon
                    width="15px"
                    height="15px"
                    sx={{ ml: 0.2, mt: 0.1 }}
                  />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 1.4 }}
                  >
                    <b>다음 달 정기 결제 금액: 300원 + 후불제 사용 요금.</b>
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 1.4 }}
                >
                  새로 시작하는 한 달의 기본 요금 300원. 이전 달의 추가 사용
                  후불제 요금. (파일 저장 용량이 0인 날은 과금 없음)
                </Typography>
          </Stack>*/}
            </CardContent>
          </Card>
          <Button
            variant="contained"
            sx={{ width: "100%", maxWidth: "sm", my: 1 }}
            onClick={() => setStartPayment(true)}
          >
            카드 등록 및 결제 시작
          </Button>
        </Box>
      </Container>
    </div>
  );
}
