import React from "react";
import { Snackbar } from "@mui/material";
import { NOTIFICATION_MESSAGE } from "../core/GlobalStateKeys";
import { useGS } from "../core/GlobalStates";

export default function NotificationSnackbar() {
  const [noti, setNoti] = useGS(NOTIFICATION_MESSAGE);

  return (
    <Snackbar
      // open={noti.show}
      open={false}
      autoHideDuration={3000}
      onClose={() => setNoti({ show: false, message: "" })}
      message={noti.message}
    />
  );
}
