import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { GET_CURRENT_FOLDER, SIGN_IN } from "../core/AppEventHandlers";
import { useNavigate } from "react-router-dom";
import CloudusbMainLogo from "../components/CloudusbMainLogo";
import CopyrightRobinTech from "./CopyrightRobinTech";
import ServiceIntro from "./ServiceIntro";
import {
  SIGN_IN_EMAIL, SIGN_IN_PASSWORD, SIGN_IN_REMEMBER_EMAIL,
  SIGN_IN_TEXT, SIGN_IN_FORGOT_PASSWORD, SIGN_IN_SUGGEST_SIGNUP
} from "../core/string_table/SignInStrings";
import { SIGN_IN_PAGE, getString } from "../core/StringTable";
import { getCookie, setCookie } from "../core/utils";

const CLOUDUSB_EMAIL = "cloudusb_email";
const REMEMBER_CLOUDUSB_EMAIL = "remember_cloudusb_email";

const getSignInString = (key) => getString(SIGN_IN_PAGE, key);

export default function SignIn() {
  const [rememberEmail, setRememberEmail] = React.useState(getCookie(REMEMBER_CLOUDUSB_EMAIL) === "true");
  const [signinEmail, setSigninEmail] = React.useState('');
  const navigate = useNavigate();

  React.useEffect(() => {
    if (rememberEmail) {
      setSigninEmail(getCookie(CLOUDUSB_EMAIL));
    }
  }, [rememberEmail, setSigninEmail]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SIGN_IN({
      email: data.get("email"),
      password: data.get("password"),
    }).then(async (res) => {
      if (res.is_ok) {
        console.log("navigate to /home");
        await GET_CURRENT_FOLDER();
        navigate("/home");
      } else {
        console.error("signin failed: ", res.message);
      }
    });
  };

  return (
    <div style={{ backgroundColor: "#ebf9cf" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CloudusbMainLogo sx={{ width: "90%", maxWidth: 700 }} />
      </Box>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={getSignInString(SIGN_IN_EMAIL)}
              name="email"
              autoComplete="email"
              autoFocus
              type="text"
              value={signinEmail}
              onChange={(e) => {
                setSigninEmail(e.target.value);
                if (rememberEmail) {
                  setCookie(CLOUDUSB_EMAIL, e.target.value);
                }
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label={getSignInString(SIGN_IN_PASSWORD)}
              name="password"
              autoComplete="current-password"
              type="password"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberEmail}
                  value="remember"
                  color="primary"
                  onChange={(e) => {
                    const new_re = getCookie(REMEMBER_CLOUDUSB_EMAIL) === "true" ? false : true;
                    setRememberEmail(new_re);
                    setCookie(REMEMBER_CLOUDUSB_EMAIL, new_re, 7);
                    if (new_re) {
                      setCookie(CLOUDUSB_EMAIL, signinEmail, 7);
                    }
                  }}
                />}
              label={getSignInString(SIGN_IN_REMEMBER_EMAIL)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {getSignInString(SIGN_IN_TEXT)}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  {getSignInString(SIGN_IN_FORGOT_PASSWORD)}
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {getSignInString(SIGN_IN_SUGGEST_SIGNUP)}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

      <ServiceIntro />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CopyrightRobinTech />
      </Box>
    </div>
  );
}
