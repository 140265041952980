import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  createTheme,
  ThemeProvider,
  Card,
  CardContent,
  TextField,
  Button,
} from "@mui/material";
import Header from "./Header";
import checkUserStates from "./CheckUserStates";
import {
  DASHBOARD_TITLE,
  DASHBOARD_SERVICE_SETTINGS,
  DASHBOARD_CHARGE_LIMIT,
  DASHBOARD_CHARGE_LIMIT_HELPER_TEXT,
  DASHBOARD_DELETE_ALL_DATE,
  DASHBOARD_DELETE_ALL_DATE_HELPER_TEXT,
  DASHBOARD_MONTHLY_CHARGE,
  DASHBOARD_SELECT_MONTH,
  DASHBOARD_DAILY_CHARGING_HISTORY,
  DASHBOARD_TABLE_DATE,
  DASHBOARD_TABLE_HOT_STORAGE_USAGE,
  DASHBOARD_TABLE_COLD_STORAGE_USAGE,
  DASHBOARD_TABLE_DAILY_CHARGE,
  DASHBOARD_CURRENT_CHARGE,
  DASHBOARD_BASE_CHARGE,
  DASHBOARD_TOTAL_DAILY_CHARGE,
  DASHBOARD_TOTAL_CHARGE,
} from "../core/string_table/DashboardStrings";
import { COMMON_SAVE } from "../core/string_table/CommonStrings";
import {
  DASHBOARD_PAGE,
  getString,
  getCommonString,
} from "../core/StringTable";

const getDashboardString = (key) => getString(DASHBOARD_PAGE, key);

const getUserStorageData = async () => {
  // Example function that fetches the user's storage data
  return [
    { date: "2021-10-01", hotGB: 10, coldGB: 5 },
    { date: "2021-10-02", hotGB: 5, coldGB: 3 },
    { date: "2021-10-03", hotGB: 3, coldGB: 2 },
    { date: "2021-10-06", hotGB: 4, coldGB: 1 },
    { date: "2021-10-07", hotGB: 7, coldGB: 3 },
    { date: "2021-10-08", hotGB: 8, coldGB: 4 },
    { date: "2021-10-09", hotGB: 5, coldGB: 2 },
    { date: "2021-10-10", hotGB: 3, coldGB: 1 },
    { date: "2021-10-11", hotGB: 4, coldGB: 2 },
    { date: "2021-10-12", hotGB: 6, coldGB: 3 },
    { date: "2021-10-14", hotGB: 7, coldGB: 4 },
    { date: "2021-10-15", hotGB: 8, coldGB: 5 },
    { date: "2021-10-16", hotGB: 3, coldGB: 1 },
    { date: "2021-10-17", hotGB: 5, coldGB: 2 },
    { date: "2021-10-22", hotGB: 9, coldGB: 4 },
    { date: "2021-10-23", hotGB: 2, coldGB: 1 },
    { date: "2021-10-29", hotGB: 12, coldGB: 6 },
    { date: "2021-10-30", hotGB: 21, coldGB: 10 },
  ];
};

const DAILY_PRICE_PER_HOT_GB = 40 / 30.0;
const DAILY_PRICE_PER_COLD_GB = 18 / 30.0;
const MONTHLY_BASE_CHARGE = 300;

const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#0d47a1",
          color: "#ffffff",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#0d47a1",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "#0d47a1",
        },
      },
    },
  },
});

export default function Dashboard() {
  const navigate = useNavigate();
  const [chargeLimit, setChargeLimit] = useState("");
  const [deleteDate, setDeleteDate] = useState("");
  const [storageData, setStorageData] = useState(null);
  const [currentCharge, setCurrentCharge] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  useEffect(() => {
    checkUserStates(navigate, "dashboard");

    // Fetch storage data for the selected month
    getUserStorageData(selectedMonth).then((data) => {
      setStorageData(data);
      calculateCurrentCharge(data);
    });
  }, [navigate, selectedMonth]);

  const calculateCurrentCharge = (data) => {
    const totalUsageHotGB = data.reduce((acc, day) => acc + day.hotGB, 0);
    const totalUsageColdGB = data.reduce((acc, day) => acc + day.coldGB, 0);
    const dailyCharge =
      totalUsageHotGB * DAILY_PRICE_PER_HOT_GB +
      totalUsageColdGB * DAILY_PRICE_PER_COLD_GB;
    const currentCharge = MONTHLY_BASE_CHARGE + dailyCharge;
    setCurrentCharge(currentCharge);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleConfirmClick = () => {
    // Logic to save charge limit and delete date
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container>
        <br />
        <Typography variant="h4" component="h1" gutterBottom>
          {getDashboardString(DASHBOARD_TITLE)}
        </Typography>

        {/* Charge Limit and Usage Period */}
        <Box mb={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getDashboardString(DASHBOARD_SERVICE_SETTINGS)}
              </Typography>
              <FormControl fullWidth margin="normal">
                <TextField
                  label={getDashboardString(DASHBOARD_CHARGE_LIMIT)}
                  value={chargeLimit}
                  onChange={(e) => setChargeLimit(e.target.value)}
                  type="number"
                  helperText={getDashboardString(
                    DASHBOARD_CHARGE_LIMIT_HELPER_TEXT
                  )}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label={getDashboardString(DASHBOARD_DELETE_ALL_DATE)}
                  type="date"
                  value={deleteDate}
                  onChange={(e) => setDeleteDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={getDashboardString(
                    DASHBOARD_DELETE_ALL_DATE_HELPER_TEXT
                  )}
                />
              </FormControl>
            </CardContent>
            <Box display="flex" justifyContent="center" m={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmClick}
              >
                {getCommonString(COMMON_SAVE)}
              </Button>
            </Box>
          </Card>
        </Box>

        {/* Monthly Charge */}
        <Box mb={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getDashboardString(DASHBOARD_MONTHLY_CHARGE)}
              </Typography>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="month-select-label">
                  {getDashboardString(DASHBOARD_SELECT_MONTH)}
                </InputLabel>
                <Select
                  labelId="month-select-label"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  label={getDashboardString(DASHBOARD_SELECT_MONTH)}
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getDashboardString(DASHBOARD_DAILY_CHARGING_HISTORY)}
              </Typography>
              {storageData ? (
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: 380, overflow: "auto" }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {getDashboardString(DASHBOARD_TABLE_DATE)}
                        </TableCell>
                        <TableCell align="right">
                          {getDashboardString(
                            DASHBOARD_TABLE_HOT_STORAGE_USAGE
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {getDashboardString(
                            DASHBOARD_TABLE_COLD_STORAGE_USAGE
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          {getDashboardString(DASHBOARD_TABLE_DAILY_CHARGE)}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {storageData.map((day, index) => (
                        <TableRow key={index}>
                          <TableCell>{day.date}</TableCell>
                          <TableCell align="right">{day.hotGB}</TableCell>
                          <TableCell align="right">{day.coldGB}</TableCell>
                          <TableCell align="right">
                            {(
                              day.hotGB * DAILY_PRICE_PER_HOT_GB +
                              day.coldGB * DAILY_PRICE_PER_COLD_GB
                            ).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>Loading storage data...</Typography>
              )}
            </CardContent>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {getDashboardString(DASHBOARD_CURRENT_CHARGE)}
              </Typography>
              <Typography>
                {getDashboardString(DASHBOARD_BASE_CHARGE)}: ₩{" "}
                {MONTHLY_BASE_CHARGE.toFixed(2)}
              </Typography>
              <Typography>
                {getDashboardString(DASHBOARD_TOTAL_DAILY_CHARGE)}: ₩{" "}
                {(currentCharge - MONTHLY_BASE_CHARGE).toFixed(2)}
              </Typography>
              <br />
              <Typography variant="h5" component="h3">
                {getDashboardString(DASHBOARD_TOTAL_CHARGE)}: ₩{" "}
                {currentCharge.toFixed(2)}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
