import Box from "@mui/material/Box";

const CloudusbIcon = (props) => {
  const color = props.color || "#ffffff";
  return (
    <Box {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500.000000 500.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
          fill={color}
          stroke="none"
        >
          <path
            d="M2175 4166 c-256 -59 -459 -221 -567 -452 -37 -78 -68 -194 -68 -252
0 -37 -3 -43 -17 -38 -92 27 -266 34 -369 15 -241 -44 -467 -222 -575 -452
-95 -205 -93 -477 6 -680 121 -247 353 -413 625 -446 49 -7 296 -11 587 -11
l503 0 0 -404 c0 -252 4 -407 10 -411 5 -3 192 -6 415 -5 l405 0 0 -131 0
-131 29 -29 30 -29 548 2 548 3 3 73 3 72 162 0 c114 0 167 4 175 12 17 17 17
359 0 376 -8 8 -61 12 -175 12 l-162 0 -3 73 -3 72 -552 3 -551 2 -26 -31
c-25 -29 -26 -36 -26 -155 l0 -124 -380 0 -380 0 0 374 0 375 558 3 c603 4
587 3 734 62 100 40 169 88 259 176 100 99 154 185 201 320 31 90 32 103 32
235 0 157 -13 220 -70 343 -131 285 -406 462 -717 462 -83 0 -169 -10 -218
-25 -13 -5 -18 6 -23 56 -15 130 -83 289 -171 401 -93 119 -270 234 -420 274
-83 22 -284 27 -360 10z m360 -86 c236 -74 414 -245 489 -471 14 -42 28 -110
31 -150 10 -119 11 -122 48 -116 18 2 68 12 112 22 113 25 261 17 370 -19 441
-148 635 -646 410 -1053 -24 -43 -70 -99 -124 -154 -70 -69 -104 -95 -178
-132 -174 -87 -98 -82 -1368 -82 -1259 0 -1175 -5 -1341 76 -253 123 -406 369
-406 653 0 210 76 382 231 529 181 170 430 235 672 176 48 -12 94 -19 101 -16
10 3 18 37 26 105 38 328 265 581 582 648 92 19 256 12 345 -16z m1545 -3020
l0 -280 -437 2 -438 3 -3 265 c-1 146 0 270 3 277 3 10 98 13 440 13 l435 0 0
-280z m140 0 l0 -280 -35 0 -35 0 0 280 0 280 35 0 35 0 0 -280z m350 0 l0
-130 -140 0 -140 0 0 130 0 130 140 0 140 0 0 -130z"
          />
        </g>
      </svg>
    </Box>
  );
};

export default CloudusbIcon;
