import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { SERVICE_INTRO, getString } from "../core/StringTable";
import {
  SERVICE_ATTRIBUTE_1_TITLE,
  SERVICE_ATTRIBUTE_1_HEADER_TEXT,
  SERVICE_ATTRIBUTE_1_EXPLANATION,
  SERVICE_ATTRIBUTE_2_TITLE,
  SERVICE_ATTRIBUTE_2_HEADER_TEXT,
  SERVICE_ATTRIBUTE_2_EXPLANATION,
  SERVICE_ATTRIBUTE_3_TITLE,
  SERVICE_ATTRIBUTE_3_HEADER_TEXT,
  SERVICE_ATTRIBUTE_3_EXPLANATION,
  SERVICE_ATTRIBUTE_4_TITLE,
  SERVICE_ATTRIBUTE_4_HEADER_TEXT,
  SERVICE_ATTRIBUTE_4_EXPLANATION,
  SERVICE_LEARN_MORE_TITLE,
  SERVICE_LEARN_MORE_HEADER_TEXT,
  SERVICE_LEARN_MORE_LINK_TEXT,
} from "../core/string_table/ServiceIntroStrings";

const getServiceIntroString = (key) => getString(SERVICE_INTRO, key);

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ServiceIntro() {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justfyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: 2,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: "center", width: "600px", maxWidth: "90vw" }}
      >
        <Grid item xs={12}>
          <Card sx={{ height: "100%", backgroundColor: "lightblue" }}>
            <CardHeader
              title={getServiceIntroString(SERVICE_ATTRIBUTE_1_TITLE)}
              subheader={getServiceIntroString(SERVICE_ATTRIBUTE_1_HEADER_TEXT)}
            />
            <CardMedia component="img" image="pay-per-use_500.png" />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {getServiceIntroString(SERVICE_ATTRIBUTE_1_EXPLANATION)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ height: "100%", backgroundColor: "lightblue" }}>
            <CardHeader
              title={getServiceIntroString(SERVICE_ATTRIBUTE_2_TITLE)}
              subheader={getServiceIntroString(SERVICE_ATTRIBUTE_2_HEADER_TEXT)}
            />
            <CardMedia component="img" image="base_charge_500.png" />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {getServiceIntroString(SERVICE_ATTRIBUTE_2_EXPLANATION)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ height: "100%", backgroundColor: "lightblue" }}>
            <CardHeader
              title={getServiceIntroString(SERVICE_ATTRIBUTE_3_TITLE)}
              subheader={getServiceIntroString(SERVICE_ATTRIBUTE_3_HEADER_TEXT)}
            />
            <CardMedia component="div" sx={{ marginTop: 3 }}>
              <video width="100%" autoPlay loop muted>
                <source src="folder.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </CardMedia>
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {getServiceIntroString(SERVICE_ATTRIBUTE_3_EXPLANATION)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ height: "100%", backgroundColor: "lightblue" }}>
            <CardHeader
              title={getServiceIntroString(SERVICE_ATTRIBUTE_4_TITLE)}
              subheader={getServiceIntroString(SERVICE_ATTRIBUTE_4_HEADER_TEXT)}
            />
            <CardMedia component="img" image="monthly_fee.png" />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {getServiceIntroString(SERVICE_ATTRIBUTE_4_EXPLANATION)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{ height: "100%", backgroundColor: "lightblue" }}
            onClick={() => {
              navigate("/details");
              window.scrollTo(0, 0);
            }}
          >
            <CardHeader
              title={getServiceIntroString(SERVICE_LEARN_MORE_TITLE)}
              subheader={getServiceIntroString(SERVICE_LEARN_MORE_HEADER_TEXT)}
            />
            <CardMedia component="img" image="cloudusb_logo_500.png" />
            <CardContent sx={{ justifyContent: "center" }}>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  navigate("/details");
                  window.scrollTo(0, 0);
                }}
                underline="none"
              >
                <Typography variant="body2" color="text.secondary">
                  {getServiceIntroString(SERVICE_LEARN_MORE_LINK_TEXT)}
                </Typography>
              </Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
