import React, { useEffect, useRef } from "react";
import { KOREAN } from "./StringTable";

const GS = {};

const INIT_GS = {
  CURRENT_FOLDER: { items: {} },
  FOLDER_TREE: {},
  CURRENT_SELECTED_ITEMS: [],
  SHOW_FOLDER_CREATION_DIALOG: false,
  SHOW_CIRCULAR_PROGRESS_DIALOG: false,
  PROGRESS_DIALOG_MESSAGE: "",
  CURRENT_SELECTION: [],
  TRIGGER_FILES_UPLOAD: false,
  TRIGGER_FILES_DOWNLOAD: false,
  ITEM_ORDERING_OPTION: "NAME_ASC",
  SEARCH_KEYWORD: "",
  SHOW_PAYMENT_GUIDE: false,
  NOTIFICATION_MESSAGE: { show: false, message: "" },
  LANGUAGE: KOREAN,
};

const registerGS = (key, value) => {
  GS[key] = {
    value,
    listeners: new Set(),
  };
};

export const initGS = () => {
  Object.entries(INIT_GS).forEach(([key, value]) => {
    registerGS(key, value);
  });
};

const makeNewValue = (val, newVal) => {
  if (!val || !newVal) {
    return newVal;
  }
  if (typeof val === "object" && !Array.isArray(val)) {
    return { ...val, ...newVal };
  }
  return newVal;
};

export function getGS(key) {
  if (!GS[key]) {
    return null;
  }
  return GS[key].value;
}

export function setGS(key, newValue) {
  if (!GS[key]) {
    registerGS(key, newValue);
    return;
  }
  GS[key].value = makeNewValue(GS[key].value, newValue);
  GS[key].listeners.forEach((listener) => {
    listener[1]((prev) => prev + 1);
  });
}

export function useGS(key, initialValue = undefined) {
  const [val, setVal] = React.useState(0);
  const isFirstRender = useRef(true);

  if (!GS[key]) {
    registerGS(key, initialValue);
  }
  if (GS[key].value === undefined) {
    GS[key].value = initialValue;
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      GS[key].listeners.add([val, setVal]);
      return () => {
        GS[key].listeners.delete([val, setVal]);
      };
    }
  }, [key, val]);

  const setGSVal = (newValue) => {
    setGS(key, newValue);
  };

  return [GS[key].value, setGSVal];
}
