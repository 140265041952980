import React from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useGS } from "../core/GlobalStates";
import { CURRENT_FOLDER } from "../core/GlobalStateKeys";
import Header from "./Header";
import NewFolderDialog from "./NewFolderDialog";
import FileBrowser from "./jamsil_filebrowser/FileBrowser";
import FileUpload from "./FileUpload";
import FileDownload from "./jamsil_filebrowser/FileDownload";
import checkUserStates from "./CheckUserStates";
import PaymentGuide from "./PaymentGuide";
import NotificationSnackbar from "./NotificationSnackbar";
import CircularProgressPopup from "./CircularProgressPopup";
import { getPlatform, Platform } from "../core/utils";
import { CALL_FLUTER_MOVE_TO_ANOTHER_FOLDER } from "../core/AppEventHandlers";

const theme = createTheme();

export default function Home() {
  const navigate = useNavigate();
  const [currentFolder] = useGS(CURRENT_FOLDER);

  const platform = getPlatform();

  React.useEffect(() => {
    checkUserStates(navigate, "home");

    if (platform === Platform.ANDROID || platform === Platform.IOS) {
      if (currentFolder) {
        CALL_FLUTER_MOVE_TO_ANOTHER_FOLDER(currentFolder.folder_id);
      }
    }
  }, [navigate, currentFolder, platform]);

  if (!currentFolder) {
    return <div></div>;
  }

  return (
    <>
      {platform != Platform.MOBILE_WEB && (
        <ThemeProvider theme={theme}>
          <Header />
          <FileBrowser height="100vh" />
          <NewFolderDialog />
          <FileUpload />
          <FileDownload />
          <PaymentGuide />
          <NotificationSnackbar />
          <CircularProgressPopup />
        </ThemeProvider>
      )}
      {platform == Platform.MOBILE_WEB && <div>mobile browser</div>}
    </>
  );
}
