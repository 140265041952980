import { KOREAN, ENGLISH } from "../StringTable";

export const SERVICE_DETAILS_TO_HOME = "SERVICE_DETAILS_TO_HOME";
export const SERVICE_DETAILS_REGISTER_PAYMENT_METHOD =
  "SERVICE_DETAILS_REGISTER_PAYMENT_METHOD";
export const SERVICE_DETAILS_1_TITLE = "SERVICE_DETAILS_1_TITLE";
export const SERVICE_DETAILS_1_HEADER_TEXT = "SERVICE_DETAILS_1_HEADER_TEXT";
export const SERVICE_DETAILS_1_EXPLANATION = "SERVICE_DETAILS_1_EXPLANATION";
export const SERVICE_DETAILS_2_TITLE = "SERVICE_DETAILS_2_TITLE";
export const SERVICE_DETAILS_2_HEADER_TEXT = "SERVICE_DETAILS_2_HEADER_TEXT";
export const SERVICE_DETAILS_2_EXPLANATION = "SERVICE_DETAILS_2_EXPLANATION";
export const SERVICE_DETAILS_3_TITLE = "SERVICE_DETAILS_3_TITLE";
export const SERVICE_DETAILS_3_HEADER_TEXT = "SERVICE_DETAILS_3_HEADER_TEXT";
export const SERVICE_DETAILS_3_EXPLANATION = "SERVICE_DETAILS_3_EXPLANATION";
export const SERVICE_DETAILS_4_TITLE = "SERVICE_DETAILS_4_TITLE";
export const SERVICE_DETAILS_4_HEADER_TEXT = "SERVICE_DETAILS_4_HEADER_TEXT";
export const SERVICE_DETAILS_4_EXPLANATION = "SERVICE_DETAILS_4_EXPLANATION";
export const SERVICE_DETAILS_5_TITLE = "SERVICE_DETAILS_5_TITLE";
export const SERVICE_DETAILS_5_HEADER_TEXT = "SERVICE_DETAILS_5_HEADER_TEXT";
export const SERVICE_DETAILS_5_EXPLANATION = "SERVICE_DETAILS_5_EXPLANATION";
export const SERVICE_DETAILS_SCENARIOS_TITLE =
  "SERVICE_DETAILS_SCENARIOS_TITLE";
export const SERVICE_DETAILS_SCENARIOS_HEADER_TEXT =
  "SERVICE_DETAILS_SCENARIOS_HEADER_TEXT";
export const SERVICE_DETAILS_SCENARIO_1_TITLE =
  "SERVICE_DETAILS_SCENARIO_1_TITLE";
export const SERVICE_DETAILS_SCENARIO_1_EXPLANATION =
  "SERVICE_DETAILS_SCENARIO_1_EXPLANATION";
export const SERVICE_DETAILS_SCENARIO_2_TITLE =
  "SERVICE_DETAILS_SCENARIO_2_TITLE";
export const SERVICE_DETAILS_SCENARIO_2_EXPLANATION =
  "SERVICE_DETAILS_SCENARIO_2_EXPLANATION";
export const SERVICE_DETAILS_SCENARIO_3_TITLE =
  "SERVICE_DETAILS_SCENARIO_3_TITLE";
export const SERVICE_DETAILS_SCENARIO_3_EXPLANATION =
  "SERVICE_DETAILS_SCENARIO_3_EXPLANATION";
export const SERVICE_DETAILS_SCENARIO_1ST_MONTH =
  "SERVICE_DETAILS_SCENARIO_1ST_MONTH";
export const SERVICE_DETAILS_SCENARIO_2ND_MONTH =
  "SERVICE_DETAILS_SCENARIO_2ND_MONTH";
export const SERVICE_DETAILS_SCENARIO_3RD_MONTH =
  "SERVICE_DETAILS_SCENARIO_3RD_MONTH";

export const SERVICE_DETAILS_STRINGS = {
  SERVICE_DETAILS_TO_HOME: {
    KOREAN: "홈으로",
    ENGLISH: "To Home",
  },
  SERVICE_DETAILS_REGISTER_PAYMENT_METHOD: {
    KOREAN: "결제수단 등록",
    ENGLISH: "Register Payment Method",
  },
  SERVICE_DETAILS_1_TITLE: {
    KOREAN: "사용량에 비례한 합리적인 요금제",
    ENGLISH: "Reasonable pricing based on usage",
  },
  SERVICE_DETAILS_1_HEADER_TEXT: {
    KOREAN: "한 달 기본요금 300원으로 시작하여 사용량에 따라 과금됩니다.",
    ENGLISH:
      "Starts with a basic fee of 300 won per month and is charged according to usage.",
  },
  SERVICE_DETAILS_1_EXPLANATION: {
    KOREAN:
      "처음 사용을 시작하면 300원의 기본 요금이 발생합니다. 기본요금 300원으로 2일 동안 100GB까지 사용 가능합니다. 더 많은 용량을 사용하거나 2일 이상 사용하게 되면 해당 용량에 대해서 30일 기준으로 1GB에 40원 과금합니다. 또한 파일을 업로드하고 28일 동안 다시 다운받지 않으면 30일 기준 1GB에 15원으로 요금이 감소합니다.",
    ENGLISH:
      "When you start using it for the first time, a basic fee of 300 won is charged. You can use up to 100GB for 2 days with a basic fee of 300 won. If you use more capacity or use it for more than 2 days, you will be charged 40 won per 1GB for 30 days. Also, if you upload files and do not download them again for 28 days, the fee decreases to 15 won per 1GB for 30 days.",
  },
  SERVICE_DETAILS_2_TITLE: {
    KOREAN: "가끔 클라우드 스토리지가 필요하다면 300원으로 충분합니다.",
    ENGLISH: "300 won is enough if you need cloud storage occasionally.",
  },
  SERVICE_DETAILS_2_HEADER_TEXT: {
    KOREAN:
      "기본요금 300원으로 2일 동안 100GB까지 사용 가능합니다. 사용 후 모든 파일을 삭제하면 추가 과금이 없습니다.",
    ENGLISH:
      "You can use up to 100GB for 2 days with a basic fee of 300 won. No additional charges if you delete all files after use.",
  },
  SERVICE_DETAILS_2_EXPLANATION: {
    KOREAN:
      "처음 서비스를 사용하기 시작하면 300원이 과금되고 2일 동안 100GB까지 사용 가능합니다. 기본요금이 적용되는 기간 이후 만약 저장된 파일이 하나도 없다면 cloudusb.io는 과금을 하지 않습니다. 이를 이용하여 가끔씩 클라우드 스토리지가 필요한 고객께서는 꼭 필요한 때 300원만 사용하셔서 100GB까지 이틀 동안 사용할 수 있습니다. 서비스 설정을 통해서 기본 이용 기간 2일 이후 모든 파일이 자동 삭제되도록 설정할 수 있어서 안심하고 사용하실 수 있습니다.",
    ENGLISH:
      "When you start using the service for the first time, 300 won is charged and you can use up to 100GB for 2 days. After the period of the basic fee, if there are no stored files, cloudusb.io does not charge. Customers who need cloud storage occasionally can pay only 300 won when necessary to use up to 100GB for two days. You can use it with peace of mind because you can set the service to automatically delete all files after the basic usage period of 2 days.",
  },
  SERVICE_DETAILS_3_TITLE: {
    KOREAN: "Hot, Cold 스토리지로 구분하여 요금 적용",
    ENGLISH: "Differentiated pricing for Hot and Cold storage.",
  },
  SERVICE_DETAILS_3_HEDER_TEXT: {
    KOREAN:
      "최근 28일 안에 업로드하거나 다운받은 파일은 Hot 스토리지, 그렇지 않으면 Cold 스트로지로 구분하여 다르게 과금합니다.",
    ENGLISH:
      "Files uploaded or downloaded within the last 28 days are charged as Hot storage, otherwise as Cold storage.",
  },
  SERVICE_DETAILS_3_EXPLANATION: {
    KOREAN:
      "파일을 업로드하거나 다운로드하면 그 날 부터 28일 동안 hot 스토리지로 분류 합니다. 그 이후로는 Cold 스토리지가 됩니다. Hot 스토리지는 30일 기준 1GB에 40원, Cold 스토리지는 30일 기준 1GB에 15원 과금 합니다. 만약 Cold 스토리지 상태에 있던 파일을 다시 다운로드 하면 Hot 스토리지 상태가 되어 28일 후에 Cold 스토리지 상태로 돌아갑니다.",
    ENGLISH:
      "When you upload or download a file, it is classified as hot storage for 28 days from that day. After that, it becomes Cold storage. Hot storage is charged at 40 won per 1GB for 30 days, and Cold storage is charged at 15 won per 1GB for 30 days. If you download a file that was in Cold storage status again, it becomes Hot storage status and returns to Cold storage status after 28 days.",
  },
  SERVICE_DETAILS_4_TITLE: {
    KOREAN: "과금 대상 용량은 1GB 단위로 증가",
    ENGLISH: "Charges are increased in 1GB units.",
  },
  SERVICE_DETAILS_4_HEADER_TEXT: {
    KOREAN:
      "저장된 파일이 하나도 없으면 과금되지 않습니다. 과금 대상 용량은 1GB 단위로 계단식으로 증가합니다.",
    ENGLISH:
      "If there are no stored files, no charge is made. The chargeable capacity increases in 1GB units in a stepwise manner.",
  },
  SERVICE_DETAILS_4_EXPLANATION: {
    KOREAN:
      "저장된 용량이 0이면 과금되지 않습니다. 파일을 업로드 하면 저장된 전체 파일의 용량보다 크거나 같은 정수로 과금 용량이 설정 됩니다. 즉, 0.3GB의 파일이 업로드 되면 과금 대상 용량은 1GB가 됩니다. 만약 업로드 파일 전체 용량이 1.7GB이면 과금 대상 용량은 2GB가 됩니다. 이렇게 전체 용량보다 크거나 같도록 과금 대상 용량은 1GB씩 증가 합니다.",
    ENGLISH:
      "If the stored capacity is 0, no charge is made. When you upload a file, the chargeable capacity is set to an integer greater than or equal to the total capacity of all stored files. That is, if a file of 0.3GB is uploaded, the chargeable capacity becomes 1GB. If the total capacity of the uploaded files is 1.7GB, the chargeable capacity becomes 2GB. In this way, the chargeable capacity increases by 1GB each time to be greater than or equal to the total capacity.",
  },
  SERVICE_DETAILS_5_TITLE: {
    KOREAN: "기본요금 결제일 기준으로 연속 사용 기간동안 한 달에 한 번 결제",
    ENGLISH:
      "Pay once a month during the continuous usage period from a base-charge payment date.",
  },
  SERVICE_DETAILS_5_HEADER_TEXT: {
    KOREAN:
      "사용을 시작할 때 기본요금 300원이 결제 됩니다. 한 달 후 기본요금 이상 사용량과 다음 한 달에 대한 기본 요금이 결제됩니다.",
    ENGLISH:
      "When you start using it, a basic fee of 300 won is charged. After one month, the usage amount exceeding the basic fee and the basic fee for the next month are charged.",
  },
  SERVICE_DETAILS_5_EXPLANATION: {
    KOREAN:
      "처음 cloudusb.io를 사용할 때 기본요금 300원이 결제됩니다. 그때부터 한 달이 지난 다음 기본요금 이상의 사용량에 대해 과금 됩니다. 그리고 다음 한 달을 위한 기본 요금 300원이 함께 결제됩니다. 이렇게 매 달 한 번 기본요금 및 초과사용량이 결제 됩니다. 만약 중간에 모든 파일을 삭제하면 파일이 없는 기간은 과금되지 않습니다. 계속해서 파일이 없다면 계속 과금되지 않다가 다시 파일을 업로드하면 300원의 기본요금이 결제되면서 매 달 결제가 반복 됩니다.",
    ENGLISH:
      "When you first use cloudusb.io, a basic fee of 300 won is charged. From then on, charges are made for usage exceeding the basic fee after one month. And the basic fee of 300 won for the next month is charged together. In this way, the basic fee and excess usage are charged once a month. If you delete all files in the middle, the period without files is not charged. If there are no files continuously, there will be no charge until you upload files again. When you upload files again, the basic fee of 300 won is charged, and the payment is repeated every month.",
  },
  SERVICE_DETAILS_SCENARIOS_TITLE: {
    KOREAN: "사용요금 예시",
    ENGLISH: "Usage fee examples",
  },
  SERVICE_DETAILS_SCENARIOS_HEADER_TEXT: {
    KOREAN:
      "아래 다른 시나리오에 따른 사용 요금을 확인해 보세요. (청구 금액은 일 단위로 계산되고 매 달 일수가 다르므로 실제 청구서는 아래 예시와 금액이 다소 달라질 수 있습니다. 아래의 3 달은 계산의 편의를 위해 모두 30일 기준으로 계산 하였습니다)",
    ENGLISH:
      "Check the usage fee according to the different scenarios below. (The billing amount is calculated on a daily basis, and since the number of days per month is different, the actual bill may differ slightly from the example and amount below. The three months below are all calculated based on 30 days for convenience of calculation.)",
  },
  SERVICE_DETAILS_SCENARIO_1_TITLE: {
    KOREAN: "시나리오 1: 50G로 시작해서 20%의 Hot 스토리지 유지",
    ENGLISH: "Scenario 1: Starting with 50G and maintaining 20% Hot storage",
  },
  SERVICE_DETAILS_SCENARIO_1_EXPLANATION: {
    KOREAN:
      "처음 50G를 업로드 하고 계속해서 전체 용량의 20%를 Hot 스토리지로 유지. 28일 후부터 전체 용량의 80%에 Cold 스토리지 요금 적용.",
    ENGLISH:
      "Upload the first 50G and continue to maintain 20% of the total capacity as Hot storage. Cold storage charges are applied to 80% of the total capacity after 28 days.",
  },
  SERVICE_DETAILS_SCENARIO_2_TITLE: {
    KOREAN:
      "시나리오 2: 매달 10G 업로드. 이전 달에 업로드한 파일들은 28일 후 Cold 스토리지로 전환",
    ENGLISH:
      "Scenario 2: Upload 10G every month. Files uploaded in the previous month are converted to Cold storage after 28 days.",
  },
  SERVICE_DETAILS_SCENARIO_2_EXPLANATION: {
    KOREAN:
      "매달 기본요금과 10G Hot 스토리지 요금이 청구되고 이전 달에 업로드한 파일들은 28일 후 Cold 스토리지로 전환됩니다.",
    ENGLISH:
      "The basic fee and 10G Hot storage fee are charged every month, and files uploaded in the previous month are converted to Cold storage after 28 days.",
  },
  SERVICE_DETAILS_SCENARIO_3_TITLE: {
    KOREAN:
      "시나리오 3: 첫 달에 50G를 10일 동안 사용. 둘째 달 사용량 없음. 셋째 달 30G 업로드하고 사용.",
    ENGLISH:
      "Scenario 3: Use 50G for 10 days in the first month. No usage in the second month. Upload and use 30G in the third month.",
  },
  SERVICE_DETAILS_SCENARIO_3_EXPLANATION: {
    KOREAN:
      "첫번째 달에 10일 동안 50G를 사용하여 기본 요금과 8일에 대한 사용 요금이 청구 됩니다. 두번째 달은 청구 금액이 없고 세번째 달에 다시 30G에 대한 사용 금액이 청구 됩니다.",
    ENGLISH:
      "In the first month, 50G is used for 10 days, and the basic fee and usage fee for 8 days are charged. There is no charge in the second month, and the usage fee for 30G is charged again in the third month.",
  },
  SERVICE_DETAILS_SCENARIO_1ST_MONTH: {
    KOREAN: "첫 달 (30일)",
    ENGLISH: "1st Month (30 days)",
  },
  SERVICE_DETAILS_SCENARIO_2ND_MONTH: {
    KOREAN: "둘째 달 (30일)",
    ENGLISH: "2nd Month (30 days)",
  },
  SERVICE_DETAILS_SCENARIO_3RD_MONTH: {
    KOREAN: "셋째 달 (30일)",
    ENGLISH: "3rd Month (30 days)",
  },
};
