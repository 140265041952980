import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";

import CloudusbMainLogo from "../components/CloudusbMainLogo";

export default function CardRegistrationFail() {
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#ebf9cf", height: "100vh" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "left",
          pt: 1,
        }}
      >
        <CloudusbMainLogo sx={{ width: "20%", maxWidth: 130 }} />
      </Box>
      <Container component="main" sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              width: "100%",
              maxWidth: "sm",
              backgroundColor: "lightblue",
            }}
          >
            <CardHeader title="카드 등록 실패" />
            <CardContent sx={{ backgroundColor: "white" }}>
              <Stack direction="row" sx={{ my: 1 }}>
                <CheckIcon fontSize="small" />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 1 }}
                >
                  카드 등록에 실패하였습니다. 다시 시도해 주세요.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Button
            variant="contained"
            sx={{ width: "100%", maxWidth: "sm", my: 1 }}
            onClick={() => navigate("/home")}
          >
            홈으로 돌아가기
          </Button>
        </Box>
      </Container>
    </div>
  );
}
