import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

import { SHOW_PAYMENT_GUIDE } from "../core/GlobalStateKeys";
import { useGS } from "../core/GlobalStates";
import { SIGN_OUT } from "../core/AppEventHandlers";

export default function PaymentGuide() {
  const [open, setOpen] = useGS(SHOW_PAYMENT_GUIDE);
  const navigate = useNavigate();

  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: "100%", m: 0, p: 0 }}>
        <Card sx={{ backgroundColor: "lightblue" }}>
          <CardHeader title="결제 수단 등록 안내" />
          <CardContent sx={{ backgroundColor: "white" }}>
            <Typography>
              <Box sx={{ pl: 1, pb: 2.5 }}>
                <ul>
                  <li>
                    월 최소 금액 300원으로 시작하는 쓰는 만큼 지불하는 클라우드
                    스토리지, cloudusb.io는 유료 서비스로 운영 됩니다.
                  </li>
                  <li>
                    안심하세요. 저장 용량이 0이 되면 더 이상 과금하지 않습니다.
                    월 청구금액이 300원이 넘지 않도록 자동 파일 삭제도 설정할 수
                    있습니다.
                  </li>
                  <li>
                    아래 링크에서 더 자세한 서비스 설명을 보시거나 바로 결제수단
                    등록으로 진행해 주세요. (다음에 하시려면{" "}
                    <Link onClick={() => SIGN_OUT().then(() => navigate("/"))}>
                      로그아웃
                    </Link>
                    )
                  </li>
                </ul>
              </Box>
            </Typography>
            <Stack spacing={1}>
              <Button variant="contained" onClick={() => navigate("/details")}>
                서비스 상세 설명
              </Button>
              <Button variant="contained" onClick={() => navigate("/checkout")}>
                결제 수단 등록
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
}
