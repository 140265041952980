import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import NorthIcon from "@mui/icons-material/North";
import FolderIcon from "@mui/icons-material/Folder";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import { createTheme } from "@mui/material/styles";
import FileMenu from "./filetoolbar/FileMenu";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "./filetoolbar/Search";
import FileSortOptionMenu from "./filetoolbar/FileSortOptionMenu";
import {
  handleGoingUp,
  triggetFilesUpload,
  triggerFilesDownload,
} from "./handlers";
import { SEARCH_KEYWORD } from "../../core/GlobalStateKeys";
import { useGS } from "../../core/GlobalStates";

const fileToolBarTheme = createTheme();

export default function FileToolBar({ minWidth, toggleFileNav = (e) => {} }) {
  const [searchKeyword, setSearchKeyword] = useGS(SEARCH_KEYWORD);
  return (
    <Box sx={{ overflowX: "auto", width: "100%" }}>
      <Toolbar variant="dense" sx={{ minWidth: minWidth, width: "100%" }}>
        <Tooltip title="폴더 탐색기">
          <Stack direction="row">
            <AccountTreeIcon
              sx={{ color: "rgba(190,192,166,1)", marginTop: 0.8 }}
            />
            <Switch onChange={(e) => toggleFileNav(e.target.checked)} />
          </Stack>
        </Tooltip>
        <Tooltip title="상위 폴더">
          <Button onClick={handleGoingUp}>
            <NorthIcon fontSize="string" />
            <FolderIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="파일 업로드">
          <Button onClick={triggetFilesUpload}>
            <UploadIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="파일 다운로드">
          <Button onClick={(e) => triggerFilesDownload()}>
            <DownloadIcon fontSize="small" />
          </Button>
        </Tooltip>
        <FileMenu title="파일" />
        <FileSortOptionMenu title="정렬 옵션" />
        <Box sx={{ flexGrow: 1 }} />
        <Search theme={fileToolBarTheme} sx={{ width: 150 }}>
          <SearchIconWrapper theme={fileToolBarTheme}>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="찾기…"
            inputProps={{ "aria-label": "search" }}
            theme={fileToolBarTheme}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </Search>
      </Toolbar>
    </Box>
  );
}
