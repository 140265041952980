import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

export default function CopyrightRobinTech() {
  const robinTechInfo =
    "| 대표: 김석현 | 서울특별시 강남구 영동대로 602, 6층 V169호 | contact@robintech.cloud | 070-4685-3042 | 사업자등록번호 194-10-02734";
  return (
    <Stack sx={{ m: 1 }}>
      <Divider light />
      <Box sx={{ mt: 1 }}>
        <Typography variant="body2" color="text.secondary" align="left">
          로빈테크(RobinTech){" "}
          {robinTechInfo}
          {/*<Link
            href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1941002734"
            underline="none"
            color="text.secondary"
          >
            <strong>사업자정보확인</strong>
          </Link>{" "}*/}
          | 통신판매업신고번호 제2023-서울강남-04088호
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2" color="text.secondary" align="left">
          {"Copyright © "}
          로빈테크(RobinTech){" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Box>
    </Stack>
  );
}
