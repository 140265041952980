import { GET_DOWNLOAD_LINKS } from "./AppEventHandlers";

export function flutterDownloadFiles(linksAndNames) {
  if (window.FileDownloader) {
    window.FileDownloader.postMessage(JSON.stringify(linksAndNames));
  }
}

export function flutterAddFolderHistory(folderId) {
  if (window.FolderHistoryAdder) {
    window.FolderHistoryAdder.postMessage(JSON.stringify({ folderId }));
  }
}

export function flutterUploadFiles(folderId) {
  if (window.FileUploader) {
    window.FileUploader.postMessage(JSON.stringify({ folderId }));
  }
}

export async function flutterOpenFile(item, folderNamePath) {
  console.log("flutterOpenFile");
  if (window.FileOpener) {
    console.log(
      "item: ",
      JSON.stringify(item),
      " folderNamePath: ",
      folderNamePath
    );
    const links = await GET_DOWNLOAD_LINKS([item]);
    console.log("links: ", JSON.stringify(links));
    const downloadLink = links[item.item_id];
    if (!downloadLink) {
      console.error("No download link for file-id: ", item.item_id);
      return;
    }
    const { name } = item;
    const itemForOpenFile = {
      url: downloadLink,
      name,
      folderNamePath,
    };
    console.log("itemForOpenFile", JSON.stringify(itemForOpenFile));
    window.FileOpener.postMessage(JSON.stringify(itemForOpenFile));
  }
}
