import Box from "@mui/material/Box";

const KoreanWonIcon = (props) => {
  const color = props.color || "#000000";
  return (
    <Box {...props}>
      <svg
        fill={color}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 612 612"
        preserveAspectRatio="xMidYMid meet"
      >
        <g>
          <g>
            <g>
              <path
                d="M558.084,0l-53.813,230.788H109.845L55.163,0H2.221l54.683,230.788H0v51.515h69.108l12.207,51.515H0v51.515h93.521
				L147.226,612h36.349l112.359-226.667h20.952L432.033,612h36.248l52.853-226.667H612v-51.515h-78.855l12.012-51.515H612v-51.515
				h-54.831L610.984,0H558.084z M122.05,282.303h167.459l-25.537,51.515H134.257L122.05,282.303z M176.208,510.872l-29.747-125.539
				h91.975L176.208,510.872z M438.798,511.578l-64.13-126.245h93.565L438.798,511.578z M480.247,333.818H348.498l-26.168-51.515
				h169.93L480.247,333.818z"
              />
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );
};

export default KoreanWonIcon;
