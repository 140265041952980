import * as React from "react";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { FOLDER_TREE, CURRENT_FOLDER } from "../../../core/GlobalStateKeys";
import { useGS } from "../../../core/GlobalStates";
import {
  GET_FOLDER_TREE,
  SET_CURRENT_FOLDER,
} from "../../../core/AppEventHandlers";
import {
  getFolderTreeRoot,
  getCurrentFolderId,
  hasChildren,
} from "../../../core/utils";

// to make a list of node ids from root to current-folder-id
const getPathToNode = (node, nodeId, path = []) => {
  if (node.folder_id === nodeId) {
    return [...path, nodeId.toString()];
  }
  if (node.children && node.children.length > 0) {
    for (const child of node.children) {
      const pathToNode = getPathToNode(child, nodeId, [
        ...path,
        node.folder_id.toString(),
      ]);
      if (pathToNode) {
        return pathToNode;
      }
    }
  }
  return null;
};

// FolderNavigator component
export default function FolderNavigator() {
  const [folderTree] = useGS(FOLDER_TREE);
  const [currentFolder] = useGS(CURRENT_FOLDER);
  const [expanded, setExpanded] = React.useState([]);

  const root = getFolderTreeRoot(folderTree);
  const currentFolderIntId = getCurrentFolderId(currentFolder);
  var currentFolderId = null;
  if (currentFolderIntId) {
    currentFolderId = currentFolderIntId.toString();
  }

  React.useEffect(() => {
    if (!root) {
      GET_FOLDER_TREE();
    } else if (currentFolderIntId) {
      const path = getPathToNode(root, currentFolderIntId);
      if (path) {
        setExpanded(path);
      }
    }
  }, [root, currentFolderId, setExpanded]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleTreeItemClick = (event, nodeId) => {
    event.stopPropagation(); // Prevent the toggle behavior
    SET_CURRENT_FOLDER(nodeId);
  };

  const renderTree = (node) => (
    <TreeItem
      key={node.folder_id.toString()}
      nodeId={node.folder_id.toString()}
      label={<span>{node.name}</span>}
      onClick={(event) => handleTreeItemClick(event, node.folder_id)}
    >
      {hasChildren(node) && node.children.map((child) => renderTree(child))}
    </TreeItem>
  );

  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={currentFolderId}
        onNodeToggle={handleToggle}
        sx={{ height: "100%", width: "100%", flexGrow: 1, overflowY: "auto" }}
      >
        {root && (
          <TreeItem
            key={root.folder_id.toString()}
            nodeId={root.folder_id.toString()}
            label={<span>Home</span>}
            onClick={(event) => handleTreeItemClick(event, root.folder_id)}
          >
            {hasChildren(root) &&
              root.children.map((child) => renderTree(child))}
          </TreeItem>
        )}
      </TreeView>
    </Box>
  );
}
