import { getGS } from "./GlobalStates";
import { LANGUAGE } from "./GlobalStateKeys";
import { COMMON_STRINGS } from "./string_table/CommonStrings";
import { ERROR_STRINGS } from "./string_table/ErrorStrings";
import { HEADER_STRINGS } from "./string_table/HeaderStrings";
import { SERVICE_INTRO_STRINGS } from "./string_table/ServiceIntroStrings";
import { SERVICE_DETAILS_STRINGS } from "./string_table/ServiceDetailsStrings";
import { SIGN_IN_STRINGS } from "./string_table/SignInStrings";
import { SIGN_UP_STRINGS } from "./string_table/SignUpStrings";
import { EMAIL_VERIFICATION_STRINGS } from "./string_table/EmailVerificationStrings";
import { DASHBOARD_STRINGS } from "./string_table/DashboardStrings";
import { ACCOUNT_STRINGS } from "./string_table/AccountStrings";
import { isValidValue } from "./utils";

// languages
export const KOREAN = "KOREAN";
export const ENGLISH = "ENGLISH";

// pages
export const HEADER = "HEADER";
export const SERVICE_INTRO = "SERVICE_INTRO";
export const SERVICE_DETAILS = "SERVICE_DETAILS";
export const SIGN_IN_PAGE = "SIGN_IN_PAGE";
export const SIGN_UP_PAGE = "SIGN_UP_PAGE";
export const EMAIL_VERIFICATION_PAGE = "EMAIL_VERIFICATION_PAGE";
export const DASHBOARD_PAGE = "DASHBOARD_PAGE";
export const ACCOUNT_PAGE = "ACCOUNT_PAGE";

export const STRING_TABLE = {
  HEADER: HEADER_STRINGS,
  SERVICE_INTRO: SERVICE_INTRO_STRINGS,
  SERVICE_DETAILS: SERVICE_DETAILS_STRINGS,
  SIGN_IN_PAGE: SIGN_IN_STRINGS,
  SIGN_UP_PAGE: SIGN_UP_STRINGS,
  EMAIL_VERIFICATION_PAGE: EMAIL_VERIFICATION_STRINGS,
  DASHBOARD_PAGE: DASHBOARD_STRINGS,
  ACCOUNT_PAGE: ACCOUNT_STRINGS,
};

export const getString = (page, key) => {
  const lang = getGS(LANGUAGE);

  if (!isValidValue(STRING_TABLE, page, key, lang)) {
    console.log(STRING_TABLE[page][key]);
    return "";
  }
  return STRING_TABLE[page][key][lang];
};

export const getCommonString = (key) => {
  const lang = getGS(LANGUAGE);
  if (!isValidValue(COMMON_STRINGS, key, lang)) {
    return "";
  }
  return COMMON_STRINGS[key][lang];
};

export const getErrorString = (key, addSpace = true) => {
  const lang = getGS(LANGUAGE);
  if (!isValidValue(ERROR_STRINGS, key, lang)) {
    return "";
  }
  if (addSpace) {
    return " " + ERROR_STRINGS[key][lang];
  }
  return ERROR_STRINGS[key][lang];
};
