import React from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { SIGN_OUT } from "../core/AppEventHandlers";
import { useNavigate } from "react-router-dom";
import {
  HEADER_HOME,
  HEADER_DASHBOARD,
  HEADER_ACCOUNT,
  HEADER_LOGOUT,
  HEADER_ACCOUNT_TOOLTIP,
} from "../core/string_table/HeaderStrings";
import { HEADER, getString } from "../core/StringTable";

const getHeaderString = (key) => getString(HEADER, key);

export default function Header() {
  const navigate = useNavigate();
  const pages = [
    { label: getHeaderString(HEADER_HOME), href: "/home" },
    { label: getHeaderString(HEADER_DASHBOARD), href: "/dashboard" },
  ];
  const settings = [
    getHeaderString(HEADER_ACCOUNT),
    getHeaderString(HEADER_LOGOUT),
  ];

  const handleClickSetting = (settingText) => {
    if (settingText === getHeaderString(HEADER_ACCOUNT)) {
      navigate("/account");
    } else if (settingText === getHeaderString(HEADER_LOGOUT)) {
      SIGN_OUT().then(() => navigate("/"));
    }
  };

  return (
    <ResponsiveAppBar
      pages={pages}
      settings={settings}
      settingsTooltip={getHeaderString(HEADER_ACCOUNT_TOOLTIP)}
      handleClickSetting={handleClickSetting}
    />
  );
}
