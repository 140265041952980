import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import { createTheme, ThemeProvider } from "@mui/material";

export const FILE_TYPE = "FILE_TYPE";
export const FOLDER_TYPE = "FOLDER_TYPE";

const HEIGHT_RATIO = 1.4;

const itemTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "h3" },
          style: {
            color: "text.secondary",
            fontSize: "2.5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
            margin: 0,
            userSelect: "none",
          },
        },
        {
          props: { variant: "body2" },
          style: {
            color: "text.primary",
            fontSize: "1rem",
            userSelect: "none",
          },
        },
        {
          props: { variant: "caption" },
          style: {
            color: "text.primary",
            fontSize: "0.7rem",
            userSelect: "none",
          },
        },
      ],
    },
    MuiStack: {
      styleOverrides: {
        root: {
          border: "2px solid",
          borderRadius: 8,
          padding: 8,
          cursor: "pointer",
          transition: "border-color 0.3s",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          margin: 0,
          color: "rgba(210, 212, 186, 1)",
        },
      },
    },
  },
});

const toVariant = (name) => {
  if (name.length >= 10) {
    return "caption";
  }
  return "body2";
};

const CommonItem = ({
  imageSize,
  children,
  name,
  selected,
  onClick,
  onDoubleClick = () => {},
}) => (
  <Stack
    sx={{
      width: imageSize,
      height: imageSize * HEIGHT_RATIO,
      borderColor: selected ? "#4dabf5" : "transparent",
    }}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
  >
    <Box sx={{ display: "flex", justifyContent: "center" }}>{children}</Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      {!selected ? (
        <Typography noWrap variant={toVariant(name)} component="p">
          {name}
        </Typography>
      ) : (
        <Typography
          variant={toVariant(name)}
          component="p"
          sx={{
            whiteSpace: "nowrap",
            animation: selected ? "scrolling-text 8s linear infinite" : "none",
          }}
        >
          {name}
        </Typography>
      )}
    </Box>
  </Stack>
);

const ThumbnailImageFile = ({
  imageSize,
  thumbnail_data,
  name,
  selected,
  onClick,
  onDoubleClick,
}) => (
  <CommonItem
    imageSize={imageSize}
    name={name}
    selected={selected}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
  >
    <img
      src={`data:image/jpeg;base64,${thumbnail_data}`}
      alt={name}
      style={{
        height: imageSize,
        width: imageSize,
        objectFit: "contain",
        padding: 4,
        margin: 0,
      }}
    />
  </CommonItem>
);

const ExtensionTextFile = ({
  imageSize,
  extension,
  name,
  selected,
  onClick,
  onDoubleClick,
}) => (
  <CommonItem
    imageSize={imageSize}
    name={name}
    selected={selected}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
  >
    <Typography
      variant="h3"
      component="h2"
      sx={{ height: imageSize, width: imageSize }}
    >
      {extension}
    </Typography>
  </CommonItem>
);

const Folder = ({ imageSize, name, selected, onClick, onDoubleClick }) => (
  <CommonItem
    imageSize={imageSize}
    name={name}
    selected={selected}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
  >
    <FolderIcon sx={{ height: imageSize, width: imageSize }} />
  </CommonItem>
);

export function Item({
  type,
  gridSize,
  imageSize,
  name,
  extension,
  thumbnail_data,
  selected,
  onClick = () => {},
  onDoubleClick = () => {},
}) {
  return (
    <ThemeProvider theme={itemTheme}>
      <Grid xs={gridSize}>
        {type === FILE_TYPE &&
          (thumbnail_data ? (
            <ThumbnailImageFile
              imageSize={imageSize}
              thumbnail_data={thumbnail_data}
              name={name}
              selected={selected}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
            />
          ) : (
            <ExtensionTextFile
              imageSize={imageSize}
              extension={extension}
              name={name}
              selected={selected}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
            />
          ))}
        {type === FOLDER_TYPE && (
          <Folder
            imageSize={imageSize}
            name={name}
            selected={selected}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
          />
        )}
      </Grid>
    </ThemeProvider>
  );
}

// CSS for scrolling effect
const style = document.createElement("style");
style.textContent = `
  @keyframes scrolling-text {
    0% {
      transform: translateX(100%);
    }
    50% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
document.head.append(style);
