import { KOREAN, ENGLISH } from "../StringTable";

export const ACCOUNT_SETTINGS_TITLE = "ACCOUNT_SETTINGS_TITLE";
export const ACCOUNT_PAYMENT_METHOD = "ACCOUNT_PAYMENT_METHOD";
export const ACCOUNT_REGISTER_NEW_PAYMENT_METHOD =
  "ACCOUNT_REGISTER_NEW_PAYMENT_METHOD";
export const ACCOUNT_CHANGE_PASSWORD = "ACCOUNT_CHANGE_PASSWORD";
export const ACCOUNT_CURRENT_PASSWORD = "ACCOUNT_CURRENT_PASSWORD";
export const ACCOUNT_NEW_PASSWORD = "ACCOUNT_NEW_PASSWORD";
export const ACCOUNT_CONFIRM_NEW_PASSWORD = "ACCOUNT_CONFIRM_NEW_PASSWORD";
export const ACCOUNT_SAVE_PASSWORD = "ACCOUNT_SAVE_PASSWORD";
export const ACCOUNT_PERSONAL_INFORMATION = "ACCOUNT_PERSONAL_INFORMATION";
export const ACCOUNT_EMAIL_ADDRESS = "ACCOUNT_EMAIL_ADDRESS";
export const ACCOUNT_PHONE_NUMBER = "ACCOUNT_PHONE_NUMBER";
export const ACCOUNT_SAVE_INFORMATION = "ACCOUNT_SAVE_INFORMATION";
export const ACCOUNT_SELECT_LANGUAGE = "ACCOUNT_SELECT_LANGUAGE";

export const ACCOUNT_STRINGS = {
  ACCOUNT_SETTINGS_TITLE: {
    KOREAN: "계정 설정",
    ENGLISH: "Account Settings",
  },
  ACCOUNT_PAYMENT_METHOD: {
    KOREAN: "결제 수단",
    ENGLISH: "Payment Method",
  },
  ACCOUNT_REGISTER_NEW_PAYMENT_METHOD: {
    KOREAN: "새로운 결제 수단 등록",
    ENGLISH: "Register New Payment Method",
  },
  ACCOUNT_CHANGE_PASSWORD: {
    KOREAN: "비밀번호 변경",
    ENGLISH: "Change Password",
  },
  ACCOUNT_CURRENT_PASSWORD: {
    KOREAN: "현재 비밀번호",
    ENGLISH: "Current Password",
  },
  ACCOUNT_NEW_PASSWORD: {
    KOREAN: "새 비밀번호",
    ENGLISH: "New Password",
  },
  ACCOUNT_CONFIRM_NEW_PASSWORD: {
    KOREAN: "새 비밀번호 확인",
    ENGLISH: "Confirm New Password",
  },
  ACCOUNT_SAVE_PASSWORD: {
    KOREAN: "비밀번호 저장",
    ENGLISH: "Save Password",
  },
  ACCOUNT_PERSONAL_INFORMATION: {
    KOREAN: "개인 정보",
    ENGLISH: "Personal Information",
  },
  ACCOUNT_EMAIL_ADDRESS: {
    KOREAN: "이메일 주소",
    ENGLISH: "Email Address",
  },
  ACCOUNT_PHONE_NUMBER: {
    KOREAN: "전화번호",
    ENGLISH: "Phone Number",
  },
  ACCOUNT_SAVE_INFORMATION: {
    KOREAN: "정보 저장",
    ENGLISH: "Save Information",
  },
  ACCOUNT_SELECT_LANGUAGE: {
    KOREAN: "언어 선택",
    ENGLISH: "Select Language",
  },
};
