export const CURRENT_FOLDER = "CURRENT_FOLDER";
export const FOLDER_TREE = "FOLDER_TREE";
export const CURRENT_SELECTED_ITEMS = "CURRENT_SELECTED_ITEMS";
export const SHOW_FOLDER_CREATION_POPUP = "SHOW_FOLDER_CREATION_POPUP";
export const SHOW_CIRCULAR_PROGRESS_POPUP = "SHOW_CIRCULAR_PROGRESS_POPUP";
export const PROGRESS_POPUP_MESSAGE = "PROGRESS_POPUP_MESSAGE";
export const CURRENT_SELECTION = "CURRENT_SELECTION";
export const TRIGGER_FILES_UPLOAD = "TRIGGER_FILES_UPLOAD";
export const TRIGGER_FILES_DOWNLOAD = "TRIGGER_FILES_DOWNLOAD";
export const ITEM_ORDERING_OPTION = "ITEM_ORDERING_OPTION";
export const SEARCH_KEYWORD = "SEARCH_KEYWORD";
export const SHOW_PAYMENT_GUIDE = "SHOW_PAYMENT_GUIDE";
export const NOTIFICATION_MESSAGE = "NOTIFICATION_MESSAGE";
export const LANGUAGE = "LANGUAGE";
