export const EMAIL_VERIFICATION_TITLE = "EMAIL_VERIFICATION_TITLE";
export const EMAIL_VERIFICATION_GUIDE = "EMAIL_VERIFICATION_GUIDE";
export const EMAIL_VERIFICATION_LABEL = "EMAIL_VERIFICATION_LABEL";
export const EMAIL_VERIFICATION_SUCCEEDED = "EMAIL_VERIFICATION_SUCCEEDED";
export const EMAIL_VERIFICATION_FAILED = "EMAIL_VERIFICATION_FAILED";
export const EMAIL_VERIFICATION_SUCCESS_MESSAGE =
  "EMAIL_VERIFICATION_SUCCESS_MESSAGE";
export const EMAIL_VERIFICATION_FAIL_MESSAGE =
  "EMAIL_VERIFICATION_FAIL_MESSAGE";
export const EMAIL_REGENERATE_VERIFICATION_CODE =
  "EMAIL_REGENERATE_VERIFICATION_CODE";
export const EMAIL_REGENERATE_VC_TITLE = "EMAIL_REGENERATE_VC_TITLE";
export const EMAIL_REGENERATE_VC_SUCCESS_MESSAGE =
  "EMAIL_REGENERATE_VC_SUCCESS_MESSAGE";
export const EMAIL_REGENERATE_VC_FAIL_MESSAGE =
  "EMAIL_REGENERATE_VC_FAIL_MESSAGE";

export const EMAIL_VERIFICATION_STRINGS = {
  EMAIL_VERIFICATION_TITLE: {
    KOREAN: "이메일 인증",
    ENGLISH: "Email Verification",
  },
  EMAIL_VERIFICATION_GUIDE: {
    KOREAN: "이메일로 전송된 인증 코드를 5분 안에입력해주세요.",
    ENGLISH:
      "Please enter the verification code sent to your email in 5 minutes.",
  },
  EMAIL_VERIFICATION_LABEL: {
    KOREAN: "인증 코드",
    ENGLISH: "Verification Code",
  },
  EMAIL_VERIFICATION_SUCCEEDED: {
    KOREAN: "이메일 인증 성공",
    ENGLISH: "Email Verification Succeeded",
  },
  EMAIL_VERIFICATION_FAILED: {
    KOREAN: "이메일 인증 실패",
    ENGLISH: "Email Verification Failed",
  },
  EMAIL_VERIFICATION_SUCCESS_MESSAGE: {
    KOREAN: "이메일 인증이 완료되었습니다. 로그인 페이지로 이동합니다.",
    ENGLISH: "Email verification has been completed. Moving to login page.",
  },
  EMAIL_VERIFICATION_FAIL_MESSAGE: {
    KOREAN: "이메일 인증에 실패했습니다.",
    ENGLISH: "Email verification failed.",
  },
  EMAIL_REGENERATE_VERIFICATION_CODE: {
    KOREAN: "인증 코드 재생성",
    ENGLISH: "Regenerate Verification Code",
  },
  EMAIL_REGENERATE_VC_TITLE: {
    KOREAN: "인증 코드 재생성",
    ENGLISH: "Regenerate Verification Code",
  },
  EMAIL_REGENERATE_VC_SUCCESS_MESSAGE: {
    KOREAN: "인증 코드가 재생성되었습니다.",
    ENGLISH: "Verification code has been regenerated.",
  },
  EMAIL_REGENERATE_VC_FAIL_MESSAGE: {
    KOREAN: "인증 코드 재생성에 실패했습니다.",
    ENGLISH: "Failed to regenerate verification code.",
  },
};
