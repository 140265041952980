import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import {
  VERIFY_USER_EMAIL,
  REGENERATE_VERIFICATION_CODE,
} from "../core/AppEventHandlers";
import { COMMON_OK, COMMON_MOVE } from "../core/string_table/CommonStrings";
import {
  EMAIL_VERIFICATION_TITLE,
  EMAIL_VERIFICATION_GUIDE,
  EMAIL_VERIFICATION_LABEL,
  EMAIL_VERIFICATION_SUCCEEDED,
  EMAIL_VERIFICATION_SUCCESS_MESSAGE,
  EMAIL_VERIFICATION_FAILED,
  EMAIL_VERIFICATION_FAIL_MESSAGE,
  EMAIL_REGENERATE_VERIFICATION_CODE,
  EMAIL_REGENERATE_VC_TITLE,
  EMAIL_REGENERATE_VC_SUCCESS_MESSAGE,
  EMAIL_REGENERATE_VC_FAIL_MESSAGE,
} from "../core/string_table/EmailVerificationStrings";
import { VERIFICATION_CODE_EXPIRED } from "../core/string_table/ErrorStrings";
import {
  EMAIL_VERIFICATION_PAGE,
  getString,
  getCommonString,
  getErrorString,
} from "../core/StringTable";
import MessagePopup from "../components/MessagePopup";
import { extractErrorCode } from "../core/utils";

const getEVString = (key) => getString(EMAIL_VERIFICATION_PAGE, key);
const EV_RESULT_SUCCESS = "SUCCESS";
const EV_RESULT_FAIL = "FAIL";
const EV_RESULT_NONE = "NONE";
const EV_REGENERATE_MESSAGE = "EV_REGENERATE_MESSAGE";

export default function EmailVerification() {
  const navigate = useNavigate();
  let { verificationKey } = useParams();
  const [evResult, setEVResult] = React.useState(EV_RESULT_NONE);
  const [messageData, setMessageData] = React.useState({
    title: "",
    message: "",
  });
  const [showRegenerateButton, setShowRegenerateButton] = React.useState(false);

  const handleEmailVerification = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const verification_data = {
      verification_code: data.get("verificationCode"),
      verification_key: verificationKey,
    };
    VERIFY_USER_EMAIL(verification_data).then((res) => {
      if (res.is_ok) {
        setMessageData({
          title: getEVString(EMAIL_VERIFICATION_SUCCEEDED),
          message: getEVString(EMAIL_VERIFICATION_SUCCESS_MESSAGE),
        });
        setEVResult(EV_RESULT_SUCCESS);
      } else {
        let message = getEVString(EMAIL_VERIFICATION_FAIL_MESSAGE);
        const errorCode = extractErrorCode(res.message);
        message += getErrorString(errorCode);
        setMessageData({
          title: getEVString(EMAIL_VERIFICATION_FAILED),
          message: message,
        });
        setEVResult(EV_RESULT_FAIL);
        if (errorCode === VERIFICATION_CODE_EXPIRED) {
          setShowRegenerateButton(true);
        }
      }
    });
  };

  const handleRegenerateVerificationCode = () => {
    const data = {
      verification_key: verificationKey,
    };
    REGENERATE_VERIFICATION_CODE(data).then((res) => {
      setEVResult(EV_REGENERATE_MESSAGE);
      if (res.is_ok) {
        setMessageData({
          title: getEVString(EMAIL_REGENERATE_VC_TITLE),
          message: getEVString(EMAIL_REGENERATE_VC_SUCCESS_MESSAGE),
        });
        setShowRegenerateButton(false);
      } else {
        let message = getEVString(EMAIL_REGENERATE_VC_FAIL_MESSAGE);
        const errorCode = extractErrorCode(res.message);
        message += getErrorString(errorCode);
        setMessageData({
          title: getEVString(EMAIL_REGENERATE_VC_TITLE),
          message: message,
        });
        setShowRegenerateButton(true);
      }
    });
  };

  const handlePopupClose = (toLogin) => {
    setEVResult(EV_RESULT_NONE);
    if (toLogin) {
      navigate("/");
    }
  };

  return (
    <>
      <MessagePopup
        open={evResult === EV_RESULT_SUCCESS}
        title={messageData.title}
        message={messageData.message}
        buttonText={getCommonString(COMMON_MOVE)}
        handleClose={() => handlePopupClose(true)}
      />
      <MessagePopup
        open={evResult === EV_RESULT_FAIL}
        title={messageData.title}
        message={messageData.message}
        buttonText={getCommonString(COMMON_OK)}
        handleClose={() => handlePopupClose(false)}
      />
      <MessagePopup
        open={evResult === EV_REGENERATE_MESSAGE}
        title={messageData.title}
        message={messageData.message}
        buttonText={getCommonString(COMMON_OK)}
        handleClose={() => handlePopupClose(false)}
      />
      <Container component="main" maxWidth="sx">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <SubscriptionsOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {getEVString(EMAIL_VERIFICATION_TITLE)}
          </Typography>
          <Typography component="h4" variant="h7">
            {getEVString(EMAIL_VERIFICATION_GUIDE)}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleEmailVerification}
            sx={{ mt: 3, width: "35%" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="verificationCode"
                  label={getEVString(EMAIL_VERIFICATION_LABEL)}
                  name="verificationCode"
                  autoComplete="verification-code"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {getCommonString(COMMON_OK)}
                </Button>
                {showRegenerateButton && (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleRegenerateVerificationCode()}
                    sx={{ mt: 1, mb: 1 }}
                  >
                    {getEVString(EMAIL_REGENERATE_VERIFICATION_CODE)}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
